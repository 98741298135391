import { theme } from '@/services/theme'
import { StyleSheet } from 'aphrodite/no-important'
import { createStyle } from 'obap-core'
export const styles = StyleSheet.create({
  invalidCredentials: createStyle({
    fontFamily: theme.fonts.corporate.regular,
  }),
  link: createStyle({
    color: theme.colors.primary.light,
    ':visited': createStyle({
      color: 'inherit',
    }),
  }),
})
