import { StyleSheet } from 'aphrodite/no-important'
import {
  StyleService,
  createStyle,
  marginMixed,
  marginHorizontal,
  S_INDENT,
  L_INDENT,
  XS_INDENT,
  MEDIA_MAX_CONTENT_WIDTH_L,
  MEDIA_MAX_CONTENT_WIDTH_S,
  M_INDENT,
  paddingHorizontal,
} from 'obap-core'

export const styles = StyleSheet.create({
  cookieBar: createStyle({
    fontFamily: StyleService.fontFamily,
    display: 'block',
    boxSizing: 'border-box',
    lineHeight: '18px',
    width: 'calc(100% - 30px)',
    padding: L_INDENT,
    backgroundColor: StyleService.colors.light,
    color: StyleService.colors.neutral.dark,
    transition: 'all .6s ease-in-out',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 10px -3px, rgba(0, 0, 0, 0.07) 0px 4px 19px 0px',
    transform: 'translateY(100%)',
    ...marginMixed(0, 'auto'),
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      padding: M_INDENT,
    }),
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      overflow: 'scroll',
    }),
  }),
  showCookieBar: createStyle({
    transform: 'translateY(0px)',
  }),
  cookieBarWrapper: createStyle({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: 1120,
    ...marginMixed(0, 'auto'),
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      display: 'block',
    }),
  }),
  cookieBarLeft: createStyle({
    alignSelf: 'center',
    textAlign: 'left',
    ...marginMixed(S_INDENT, 0),
    fontSize: 14,
    fontWeight: 400,
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      textAlign: 'center',
      fontSize: 16,
    }),
  }),
  cookieBarRight: createStyle({
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      textAlign: 'center',
    }),
  }),
  text: createStyle({
    paddingRight: XS_INDENT,
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      ...paddingHorizontal(M_INDENT),
      paddingBottom: XS_INDENT,
    }),
  }),
  cookieBarButtons: createStyle({
    position: 'static',
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }),
  }),
  button: createStyle({
    fontFamily: StyleService.fontFamily,
    fontWeight: 500,
    lineHeight: '22px',
    ':last-child': createStyle({
      marginLeft: M_INDENT,
      [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
        marginLeft: 'auto',
        marginTop: XS_INDENT,
      }),
    }),
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      minWidth: 255,
      ...marginHorizontal('auto'),
    }),
  }),
})
