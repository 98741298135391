import * as React from 'react'

import { useLocation } from 'react-router-dom'

import { SignInFormComponent } from './sign-in-form.component'

import { SIGN_IN_PATH, FORGOT_PASSWORD_PATH } from '@/utils/constants'

import { SignInFormProps as Props } from './sign-in-form.types'

const ENTER_CODE = 'Enter'

export const SignInForm: React.FC<Props> = (props): JSX.Element => {
  const { handleSubmit } = props
  const location = useLocation()
  const onFieldKeyDown = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.key === ENTER_CODE || event.code === ENTER_CODE) {
        handleSubmit()
      }
    },
    [handleSubmit],
  )
  const forgotPasswordPath = React.useMemo(
    () => location.pathname.replace(SIGN_IN_PATH, FORGOT_PASSWORD_PATH),
    [location.pathname],
  )
  return (
    <SignInFormComponent
      onFieldKeyDown={onFieldKeyDown}
      forgotPasswordPath={forgotPasswordPath}
      {...props}
    />
  )
}
