export enum COOKIE_NAME {
  necessary = 'strictlyNecessary',
  functional = 'functional',
  marketing = 'marketing',
}

export interface CookiesData {
  [COOKIE_NAME.necessary]?: boolean
  [COOKIE_NAME.functional]?: boolean
  [COOKIE_NAME.marketing]?: boolean
}
