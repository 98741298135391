import { composeApplicationRoutes } from '@/utils/helpers'

export const MAIN_PAGE_PATH = '/'
export const UNIVERSAL_ROUTE = '*'

export const REDIRECT_PARAM = 'redirect'
export const PASSWORD_PARAM = 'password'

export const SECTION_PARAM = 'section'
export const API_PARAM = 'api'
export const VERSION_PARAM = 'version'

export const USER_DATA_PARAM = 'personal-data'
export const DELETE_PARAM = 'delete'

export const SIGN_IN_PATH = `${MAIN_PAGE_PATH}sign-in`
export const SIGN_UP_PATH = `${MAIN_PAGE_PATH}sign-up`
export const FORGOT_PASSWORD_PATH = `${MAIN_PAGE_PATH}forgot-password`
export const COOKIE_SETTINGS_PATH = `${MAIN_PAGE_PATH}cookie-settings`

export const TERMS_AND_CONDITIONS_PATH = `${MAIN_PAGE_PATH}terms-and-conditions`
export const PRIVACY_NOTICE_PATH = `${MAIN_PAGE_PATH}privacy-notice`
export const COOKIE_NOTICE_PATH = `${MAIN_PAGE_PATH}cookie-notice`

export const DOCUMENTATION_PATH = `${MAIN_PAGE_PATH}api-documentation`
export const DOCUMENTATION_ROUTE = `${DOCUMENTATION_PATH}/:${SECTION_PARAM}?/:${API_PARAM}?/:${VERSION_PARAM}?` // eslint-disable-line
export const DOCUMENTATION_SIGN_IN_PATH = `${DOCUMENTATION_PATH}${SIGN_IN_PATH}`
export const DOCUMENTATION_SIGN_UP_PATH = `${DOCUMENTATION_PATH}${SIGN_UP_PATH}`
export const DOCUMENTATION_FORGOT_PASSWORD_PATH = `${DOCUMENTATION_PATH}${FORGOT_PASSWORD_PATH}`
export const DOCUMENTATION_SECTION_ROUTE = `${DOCUMENTATION_PATH}/:${SECTION_PARAM}`
export const DOCUMENTATION_SPEC_ROUTE = `${DOCUMENTATION_SECTION_ROUTE}/:${API_PARAM}/:${VERSION_PARAM}` // eslint-disable-line
export const DOCUMENTATION_LOAN_ORIGINATION_SECTION_PATH = `${DOCUMENTATION_PATH}/loan-origination`
export const DOCUMENTATION_LOAN_LIFE_CYCLE_SECTION_PATH = `${DOCUMENTATION_PATH}/loan-life-cycle`
export const DOCUMENTATION_COLLECTION_SECTION_PATH = `${DOCUMENTATION_PATH}/collection`
export const DOCUMENTATION_CLOUD_SECTION_PATH = `${DOCUMENTATION_PATH}/credit-cloud`

export const WORKPLACE_PATH = `${MAIN_PAGE_PATH}workplace`

export const PROFILE_PATH = `${WORKPLACE_PATH}/profile`
export const PROFILE_PERSONAL_DATA_PATH = `${PROFILE_PATH}/${USER_DATA_PARAM}`
export const PROFILE_PASSWORD_PATH = `${PROFILE_PATH}/${PASSWORD_PARAM}`
export const PROFILE_DELETE_PATH = `${PROFILE_PATH}/${DELETE_PARAM}`
export const USER_PROFILE_ROUTE = `${PROFILE_PATH}/:${SECTION_PARAM}${composeApplicationRoutes([
  PASSWORD_PARAM,
  USER_DATA_PARAM,
  DELETE_PARAM,
])}`
//sign in endpoints

export const SIGN_IN_APP_ROUTE = '/:path*/sign-in'
export const DOCUMENTATION_VERSION_SIGN_IN_APP_ROUTE = `${DOCUMENTATION_PATH}/:${SECTION_PARAM}/sign-in`

export const SIGN_UP_APP_ROUTE = '/:path*/sign-up'
export const DOCUMENTATION_VERSION_SIGN_UP_APP_ROUTE = `${DOCUMENTATION_PATH}/:${SECTION_PARAM}/sign-up`
export const FORGOT_PASSWORD_APP_ROUTE = '/:path*/forgot-password'
export const COOKIE_SETTINGS_APP_ROUTE = '/:path*/cookie-settings'

export const API_PORTALS_PATH = `${MAIN_PAGE_PATH}api-portals`
export const MAIN_PAGE_ROUTE = composeApplicationRoutes([
  MAIN_PAGE_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  FORGOT_PASSWORD_PATH,
  COOKIE_SETTINGS_PATH,
])
export const MODAL_ENDPOINTS = composeApplicationRoutes([
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  FORGOT_PASSWORD_PATH,
  COOKIE_SETTINGS_PATH,
])
