import * as React from 'react'

import { WizardStepProps } from '@/components/blocks/wizard/wizard-step/types'

import { WizardProps } from './types'

export const Wizard: React.FC<WizardProps> = (props): JSX.Element => {
  const renderStep = (
    child: React.ReactElement<WizardStepProps>,
    index: number,
  ): JSX.Element | null => {
    const { activeIndex } = props
    const isActive = index === activeIndex
    if (isActive) {
      return React.cloneElement(child, { isActive })
    }
    return null
  }
  const { children } = props
  // @ts-expect-error fix before release
  return <div>{React.Children.map(children, renderStep)}</div>
}
