import * as React from 'react'

import { Form } from 'react-final-form'
import {
  Button,
  Dialog,
  Separator,
  DialogHeader,
  ContentBlockInner,
  DialogActionBar,
  LoadingBlock,
} from 'obap-core'

import { Wizard, WizardStep } from '@/components/blocks/wizard'
import { SignInForm } from '@/components/forms/sign-in-form'

import { SignInPageComponentProps as Props } from './sign-in-page.types'
import { styles } from './sign-in-page.styles'

export const SignInPageComponent: React.FunctionComponent<Props> = (props): JSX.Element => {
  const {
    formData,
    formStep,
    authentication,
    signUpPath,
    onSubmit,
    formValidation,
    onChange,
    onClose,
    onPrevStep,
  } = props
  return (
    <Dialog active size="small" close={onClose}>
      <ContentBlockInner blockStyle={styles.contentBlock}>
        <DialogHeader title="Credit Solution Portal | Tietoevry Banking" onClose={onClose} />
      </ContentBlockInner>
      <Separator />
      <ContentBlockInner blockStyle={styles.contentBlock}>
        <Form
          initialValues={formData?.values}
          validate={formValidation}
          onSubmit={onSubmit}
          // @ts-expect-error fix before release
          component={SignInForm}
          {...{
            onChange,
            activeStep: formStep,
          }}
        />
      </ContentBlockInner>
      <Wizard activeIndex={formStep}>
        <WizardStep>
          <DialogActionBar>
            <Button
              uppercased
              superellipsed
              buttonType="primary"
              onClick={onSubmit}
              disabled={formData?.invalid}
            >
              Sign in
            </Button>
            <Button uppercased superellipsed component="link" buttonType="default" to={signUpPath}>
              Create account
            </Button>
          </DialogActionBar>
        </WizardStep>
        <WizardStep>
          <DialogActionBar>
            <Button uppercased superellipsed buttonType="default" onClick={onPrevStep}>
              Prev step
            </Button>
            <Button
              uppercased
              superellipsed
              buttonType="primary"
              disabled={formData?.invalid || authentication}
              onClick={onSubmit}
            >
              <LoadingBlock
                isLoading={Boolean(authentication)}
                isLoaded={!authentication}
                loaderSize="small"
                elementStyle={styles.loaderElement}
              >
                Sign in
              </LoadingBlock>
            </Button>
          </DialogActionBar>
        </WizardStep>
      </Wizard>
    </Dialog>
  )
}
