import { COOKIE_NAME, CookiesData } from '@/types'

import Cookies from 'js-cookie'
import { CONSENT_COOKIE_NAME } from '@/utils/constants'

const consentCookie = Cookies.get(CONSENT_COOKIE_NAME)
//TODO: convert to hooks
export const saveCookiePreferences = (cookiePreferences: CookiesData) => {
  if (cookiePreferences[COOKIE_NAME.functional] === false) {
    //@ts-expect-error ts buggin about gtm
    window['ga-disable-UA-151179072-1'] = true
  }
}
export const saveCookies = (CookiesData: CookiesData) => {
  if (!consentCookie) {
    //disables gtm
    saveCookiePreferences(CookiesData)
    //makes consent cookie
    Cookies.set(CONSENT_COOKIE_NAME, JSON.stringify(CookiesData), { path: '/' })
  }
}
