import * as React from 'react'
import { Form } from 'react-final-form'
import { useHistory, useLocation } from 'react-router-dom'
import { cn, Dialog, DialogHeader } from 'obap-core'

import { CookieSettingsForm } from '@/components/forms/cookie-settings-form/cookie-settings.form'
import { validateCookieSettingsForm } from '@/components/forms/cookie-settings-form/cookie-settings-form.validation'

import { CookiesData } from '@/types'
import { cookieDescription, initialCookieSettings } from '@/utils/configs/cookies-consent.config'
import { COOKIE_NOTICE_PATH, COOKIE_SETTINGS_PATH } from '@/utils/constants'

import { CookieSettingsPagePropsType } from './cookie-settings-page.types'
import { styles } from './cookie-settings-page.styles'
import { saveCookies } from '@/utils/helpers'
import { LayoutContext } from '@/contexts'

export const CookieSettingsPage: React.FC<CookieSettingsPagePropsType> = () => {
  const [open, setOpen] = React.useState(true)

  const { pathname } = useLocation()
  const history = useHistory()
  const { setCookiesConsent } = React.useContext(LayoutContext)
  const closePage = React.useCallback(() => {
    const currentPath = pathname
    const prevPath = currentPath.replace(COOKIE_SETTINGS_PATH, '')
    history.push(prevPath)
  }, [])

  const handleEsc = React.useCallback(event => {
    if (event.keyCode === 27 && open) {
      setOpen(false)
      closePage()
    }
  }, [])

  const onSubmit = React.useCallback((formData: CookiesData): void => {
    saveCookies(formData)
    setCookiesConsent(true)
    closePage()
  }, [])

  const acceptAllCookiesAndClose = React.useCallback(() => {
    saveCookies(initialCookieSettings)
    setCookiesConsent(true)
    closePage()
  }, [])

  React.useEffect(() => {
    document.addEventListener('keydown', handleEsc, false)
    return () => {
      document.removeEventListener('keydown', handleEsc, false)
    }
  }, [])

  return (
    <Dialog active={open} size="medium" blockStyle={styles.cookieSettings}>
      <div>
        <div className={cn(styles.cookieSettingsContent)}>
          <div className={cn(styles.cookieSettingsHeader)}>
            <DialogHeader
              title="Cookie settings"
              onClose={() => {
                setOpen(false)
                closePage()
              }}
            />
            <p className={cn(styles.description)}>
              {cookieDescription}
              &nbsp;
              <a href={COOKIE_NOTICE_PATH}>Learn more</a>
            </p>
          </div>
          <Form
            initialValues={initialCookieSettings}
            onSubmit={onSubmit}
            component={CookieSettingsForm}
            validate={validateCookieSettingsForm}
            {...{
              handleAllCookies: acceptAllCookiesAndClose,
            }}
          />
        </div>
      </div>
    </Dialog>
  )
}
