import { theme } from '@/services/theme'
import { StyleSheet } from 'aphrodite/no-important'
import { createStyle, L_INDENT, StyleService } from 'obap-core'

export const styles = StyleSheet.create({
  contentBlock: createStyle({
    padding: L_INDENT,
    fontFamily: theme.fonts.corporate.regular,
  }),
  actions: createStyle({
    width: '100%',
    justifyContent: 'space-between',
  }),
  loaderElement: createStyle({
    backgroundColor: StyleService.colors.light,
  }),
})
