import * as React from 'react'
import { Icon } from '@mdi/react'
import { mdiChevronDown } from '@mdi/js/mdi'
import { cn } from 'obap-core'

import { styles } from './expansion-panel.styles'
import { ExpansionPanelPropsType } from './expansion-panel.types'

export const ExpansionPanel: React.FC<ExpansionPanelPropsType> = ({
  handleTitleClick: onClick,
  title,
  children,
  expandIconToRight,
  statusIcon,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleClick = React.useCallback((): void => {
    onClick && onClick()
    setIsOpen(!isOpen)
  }, [isOpen])

  const renderHeader = () => {
    if (expandIconToRight) {
      return (
        <React.Fragment>
          {statusIcon}
          {title}
          <Icon
            className={cn(styles.iconExpandRight, [isOpen && styles.iconExpandActive])}
            path={mdiChevronDown}
            size="24px"
          />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <Icon
          className={cn(styles.iconExpand, [isOpen && styles.iconExpandActive])}
          path={mdiChevronDown}
          size="24px"
        />
        {title}
      </React.Fragment>
    )
  }

  return (
    <div
      className={cn(styles.expansionPanelRoot, styles.separateLine, [
        expandIconToRight && styles.expansionPanelRootIcon,
      ])}
    >
      <div
        className={cn(styles.labelWrapper, [isOpen && styles.expandedPanel])}
        onClick={handleClick}
      >
        {renderHeader()}
      </div>
      <div
        className={cn(
          styles.content,
          [isOpen && styles.contentActive],
          [statusIcon && styles.contentActiveNoPadding],
        )}
      >
        {children}
      </div>
    </div>
  )
}
