import { StyleSheet } from 'aphrodite/no-important'
import {
  XS_INDENT,
  S_INDENT,
  M_INDENT,
  XL_INDENT,
  createStyle,
  border,
  marginHorizontal,
  paddingHorizontal,
  generatePixelsFromValue,
  MEDIA_MAX_CONTENT_WIDTH_M,
  centeredContent,
} from 'obap-core'
import { theme } from '@/services/theme'

export const styles = StyleSheet.create({
  footer: createStyle({
    display: 'block',
    width: '100%',
    backgroundColor: '#280070',
    paddingTop: 60,
    paddingBottom: 45,
  }),
  container: createStyle({
    display: 'flex',
    flexDirection: 'row',
    ...marginHorizontal('auto'),
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      flexDirection: 'column',
    }),
  }),
  copyrightContainer: createStyle({
    marginTop: 50,
  }),
  contentContainer: createStyle({
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    ...paddingHorizontal(S_INDENT),
  }),
  primaryContentContainer: createStyle({
    flex: 2,
  }),
  secondaryContentContainer: createStyle({
    flex: 1,
  }),
  socialContainer: createStyle({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      justifyContent: 'flex-start',
    }),
  }),
  logo: createStyle({
    marginBottom: XS_INDENT,
    fill: theme.colors.primary.light,
  }),
  listTitle: createStyle({
    fontSize: 12.3,
    letterSpacing: 0.1,
    lineHeight: generatePixelsFromValue(27),
    fontFamily: theme.fonts.corporate.bold,
    textTransform: 'uppercase',
    color: theme.colors.primary.light,
  }),
  listItem: createStyle({
    listStyle: 'none',
  }),
  text: createStyle({
    fontFamily: theme.fonts.corporate.light,
    color: theme.colors.primary.light,
    fontSize: 12.3,
  }),
  description: createStyle({
    lineHeight: generatePixelsFromValue(26),
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      marginBottom: S_INDENT,
    }),
  }),
  link: createStyle({
    textDecoration: 'none',
    lineHeight: generatePixelsFromValue(33),
  }),
  copyright: createStyle({
    display: 'block',
    width: '100%',
    borderTop: border(1, 'solid', theme.colors.primary.light),
    paddingTop: S_INDENT,
    fontSize: 12.3,
    lineHeight: generatePixelsFromValue(26),
    fontFamily: theme.fonts.corporate.light,
    color: theme.colors.primary.light,
  }),
  socialLink: createStyle({
    width: 32,
    height: 32,
    borderRadius: 16,
    overflow: 'hidden',
    boxShadow: '0 0 2px rgb(6 55 82 / 40%)',
    marginTop: M_INDENT,
    marginRight: S_INDENT,
    ...centeredContent,
    ':last-child': createStyle({
      marginRight: 0,
    }),
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      marginRight: XL_INDENT,
    }),
  }),
  linkedin: createStyle({
    transform: 'translateY(-4px)',
  }),
  facebook: createStyle({
    transform: 'translateY(-1px)',
  }),
  twitter: createStyle({
    transform: 'translateY(-2px)',
  }),
  youtube: createStyle({
    transform: 'translateY(-2px)',
  }),
})
