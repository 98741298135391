import * as React from 'react'
import { Router } from 'obap-core'
import { MODAL_ROUTES, ROUTES } from './router-config'

export const ApplicationRouter = (): JSX.Element => {
  return (
    <React.Suspense fallback={React.Fragment}>
      <Router routerComponent={React.Fragment} routes={MODAL_ROUTES} />
      <Router routerComponent={React.Fragment} routes={ROUTES} />
    </React.Suspense>
  )
}
