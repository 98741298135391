import { StyleSheet } from 'aphrodite/no-important'
import { createStyle, M_INDENT, StyleService } from 'obap-core'

export const styles = StyleSheet.create({
  control: createStyle({
    width: '100%',
    marginBottom: M_INDENT,
    ':last-child': createStyle({
      marginBottom: 0,
    }),
  }),
  rightSideText: createStyle({
    textAlign: 'right',
  }),
  controlLink: createStyle({
    fontSize: 14,
    color: StyleService.colors.neutral.semidark,
  }),
})
