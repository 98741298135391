export interface FormValidationConfig<ValuesType> {
  [key: string]: FieldValidationConfig<ValuesType>
}

export interface FieldValidationConfig<ValuesType> {
  isRequired?: boolean
  requiredErrorMessage?: string
  comparator?: RegExp | ((values: ValuesType) => string | void) | RegExp[]
  typeErrorMessage?: string
}

export class ValidatorHelper {
  public static urlRegExp = /(https?:\/\/[^\s]+)/i
  public static domainRegExp =
    /^(?!(https:\/\/|http:\/\/|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/i // eslint-disable-line
  public static domainsCommaSeperatedRegExp =
    /^\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z]+)\s*(?:,\s*(?:(?:\w+(?:-+\w+)*\.)+[a-z]+)\s*)*$/ // eslint-disable-line
  public static emailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  public static passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/ // eslint-disable-line
  public static oldPasswordRegExp = /.{1,10}/
  public static specialCharsetEntryRegExp = /^.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*$/
  public static lowercaseEntryRegExp = /^.*[a-z].*$/
  public static uppercaseEntryRegExp = /^.*[A-Z].*$/
  public static digitEntryRegExp = /^.*\d.*$/i
  public static nameRegExp =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽĶĻŅĢĀāĒēĪīķļņģ∂ð\s,.'-]+$/ // eslint-disable-line
  public static numberRegExp = /^\d+$/i
  public static doubleRegExp = /^\d+(\.\d{1,2})?$/i
  public static usernameRegExp = /^[a-zA-Z0-9-_]{4,20}$/i
  public static positiveFloatNumberRegExp = /^([1-9]\d{0,13}|0)(\.\d{1,2})?$/i

  static validateUsername = (value: string): boolean => {
    const regex = /^[a-zA-Z0-9]{4,}$/g

    if (regex.exec(value)) {
      return true
    }

    return false
  }

  static validateEmail = (value: string): boolean => {
    const regex = ValidatorHelper.emailRegExp

    if (regex.exec(value)) {
      return true
    }

    return false
  }

  static validatePassword = (value: string): boolean => {
    const regex = ValidatorHelper.passwordRegExp

    if (regex.exec((value || '').trim())) {
      return true
    }

    return false
  }

  static commonFormValidator = <ValuesType, ConfigType>(values: ValuesType, config: ConfigType) => {
    const errors: any = {}

    Object.keys(config || {}).forEach((fieldName: string) => {
      // @ts-expect-error fix before release
      const fieldConfig: FieldValidationConfig<ValuesType> = config[fieldName]
      // @ts-expect-error fix before release
      const fieldValue: any = values[fieldName]
      const isValueIsArray = Array.isArray(fieldValue)
      let value: any

      if (isValueIsArray) {
        value = Boolean(fieldValue.length)
      } else {
        value = fieldValue
      }

      if (!value && fieldConfig.isRequired) {
        errors[fieldName] = fieldConfig.requiredErrorMessage || 'Field is required'
      } else if (fieldConfig.comparator) {
        if (typeof fieldConfig.comparator === 'function') {
          errors[fieldName] = fieldConfig.comparator(values)
        } else if (Array.isArray(fieldConfig.comparator)) {
          const validRegExp = fieldConfig.comparator.find((regExp: RegExp) => regExp.test(value))
          if (!validRegExp) {
            errors[fieldName] = fieldConfig.typeErrorMessage
          }
        } else {
          const testResult = fieldConfig.comparator.test(value)
          if (!testResult) {
            errors[fieldName] = fieldConfig.typeErrorMessage
          }
        }
      }
    })

    return errors
  }
}
