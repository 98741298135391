interface Mods {
  [key: string]: boolean
}

type ElementOrMods = string | Mods

export const bem = (componentName: string) => {
  return (elementOrMods: ElementOrMods, modsParam: Mods = {}) => {
    let mods: Mods = { ...modsParam }
    if (!elementOrMods) {
      return componentName
    }

    let element

    if (typeof elementOrMods === 'string') {
      element = elementOrMods
    } else {
      mods = elementOrMods
    }

    if (process.env.NODE_ENV !== 'production') {
      if (element && typeof element !== 'string') {
        throw new Error('You must provide `element` as string')
      }

      if (mods && typeof mods !== 'object') {
        throw new Error('You must provide `mods` as plain object')
      }
    }

    let base = componentName
    if (element) {
      base += `__${element}`
    }

    return (
      base +
      (mods
        ? Object.keys(mods).reduce((resultParam, name) => {
            let result = resultParam
            const value = mods[name]

            if (value) {
              result += ` ${
                typeof value === 'boolean' ? `${base}--${name}` : `${base}--${name}_${value}`
              }`
            }

            return result
          }, '')
        : '')
    )
  }
}

export const className = (
  mainClassName: string,
  additionalClassName: string | undefined,
): string => {
  if (additionalClassName) {
    return `${mainClassName} ${additionalClassName}`
  }

  return mainClassName
}
