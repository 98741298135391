import { Link } from 'react-router-dom'

import { cn, HeadingBlock } from 'obap-core'

import { styles } from './navigation-bar.styles'
import {
  API_PORTALS_PATH,
  DOCUMENTATION_COLLECTION_SECTION_PATH,
  DOCUMENTATION_LOAN_LIFE_CYCLE_SECTION_PATH,
  DOCUMENTATION_LOAN_ORIGINATION_SECTION_PATH,
  DOCUMENTATION_CLOUD_SECTION_PATH,
  DOCUMENTATION_PATH,
  MAIN_PAGE_PATH,
  PROFILE_PERSONAL_DATA_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from '@/utils/constants'

//TODO: Add correct login paths
export const mainLinks = [
  {
    id: 'api-portals',
    label: 'Our Api Portals',
    pathname: API_PORTALS_PATH,
  },

  {
    id: 'documentation',
    label: 'API Documentation',
    pathname: DOCUMENTATION_PATH,
    dropdownContent: (
      <HeadingBlock
        commonInformation={{
          title: 'API Documentation',
          description: 'Get to know our APIs',
        }}
        links={[
          {
            title: 'Products',
            links: [
              {
                label: 'Loan Life Cycle',
                pathname: DOCUMENTATION_LOAN_LIFE_CYCLE_SECTION_PATH,
              },
              {
                label: 'Loan Origination',
                pathname: DOCUMENTATION_LOAN_ORIGINATION_SECTION_PATH,
              },
              {
                label: 'Collection',
                pathname: DOCUMENTATION_COLLECTION_SECTION_PATH,
              },
              {
                label: 'Credit Cloud',
                pathname: DOCUMENTATION_CLOUD_SECTION_PATH,
              },
            ],
          },
        ]}
        contact={{
          contactUrl: 'mailto:saas.support@tietoevry.com',
          label: 'Got questions? Contact our support',
          image: 'https://www.tietoevry.com/siteassets/icons/social-media/2022_oval.png',
        }}
      />
    ),
  },
]

export const userMenuLinks = (logout: () => void) => [
  {
    label: 'User Profile',
    pathname: PROFILE_PERSONAL_DATA_PATH,
  },
  {
    label: 'Logout',
    pathname: MAIN_PAGE_PATH,
    attr: {
      onClick: logout,
    },
  },
]
export const authLinks = (logout: () => void, pathname: string, username?: string) => [
  {
    id: 'register',
    label: 'REGISTER',
    pathname: pathname ? `${pathname}${SIGN_UP_PATH}` : SIGN_UP_PATH,
    authRequired: false,
  },
  {
    id: 'login',
    label: 'LOGIN',
    pathname: pathname ? `${pathname}${SIGN_IN_PATH}` : SIGN_IN_PATH,
    authRequired: false,
  },
  {
    id: 'user',
    label: username || '',
    authRequired: true,
    elementStyle: styles.userMenuElement,
    dropdownStyle: styles.userDropdownContent,
    dropdownContent: (
      <div className={cn(styles.linksList)}>
        {userMenuLinks(logout).map(link => (
          <Link key={link.pathname} to={link.pathname} className={cn(styles.link)} {...link.attr}>
            {link.label}
          </Link>
        ))}
      </div>
    ),
  },
]
