import * as React from 'react'

import * as uuid from 'uuid'
import { Button } from 'obap-core'

import { Dialog } from '@/components/blocks/legacy/dialog'
import { ModalHeader } from '@/components/blocks/legacy/modal-header'
import { Title } from '@/components/blocks/legacy/title'
import { ModalActionBar } from '@/components/blocks/legacy/modal-action-bar'

import { queryObjectToString } from '@/utils/helpers/endpoint.helper'
import { bem } from '@/utils/helpers/bem-legacy.helper'
import { termsAndConditions } from '@/utils/mocks/terms-conditions'
import { privacyNotice } from '@/utils/mocks/privacy-notice'
import { KEYCLOAK_REGISTRATION_URL, SIGN_UP_PATH, REALM_ID } from '@/utils/constants'

import { SignUpPageProps, SignUpPageState, SignUpPageSection } from './types'

import './styles.scss'

const cn = bem('sign-up-page')
/* eslint react/no-multi-comp: "off" */
export class SignUpPage extends React.Component<SignUpPageProps, SignUpPageState> {
  private iframe: React.RefObject<HTMLIFrameElement>

  constructor(props: SignUpPageProps) {
    super(props)

    this.state = {
      iframeHeight: 600,
      visibleSection: null,
      iframeUrl: this.generateRegistrationUrl(),
    }

    this.iframe = React.createRef()
  }

  public componentDidMount(): void {
    window.addEventListener('message', this.handleIframeMessages)
  }

  public componentWillUnmount(): void {
    window.removeEventListener('message', this.handleIframeMessages)
  }

  private handleIframeMessages = (message: any) => {
    const messageData = JSON.parse(message.data)

    Object.keys(messageData).forEach((key: string): void => {
      const notification = messageData[key]

      if (
        notification.type === 'data' &&
        (notification.data === 'activateSignUpTermsAndConditions' ||
          notification.data === 'activateSignUpDataPrivacy')
      ) {
        this.changeVisibleSection(notification.data)
      }
    })
  }

  private changeVisibleSection = (section: SignUpPageSection | null): void => {
    this.setState({ visibleSection: section })
  }

  private closePage = (): void => {
    const {
      history: { push },
      location: { pathname },
    } = this.props
    const appEndpoint = pathname.replace(SIGN_UP_PATH, '')
    push(appEndpoint)
  }

  private hideAbsoluteSection = (): void => {
    this.setState({ visibleSection: null })
  }

  private generateRegistrationUrl = (): string => {
    const baseUrl = KEYCLOAK_REGISTRATION_URL.replace('{realmId}', REALM_ID)
    const params = {
      client_id: 'public_client', // eslint-disable-line
      redirect_uri: '/', // eslint-disable-line
      state: uuid.v1(),
      response_mode: 'fragment', // eslint-disable-line
      response_type: 'code', // eslint-disable-line
      scope: 'openid',
    }

    return `${baseUrl}${queryObjectToString(params)}`
  }

  private acceptTermsAndConditions = (): void => {
    const iframe = this.iframe.current
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        JSON.stringify({
          activateSignUpTermsAndConditions: {
            type: 'data',
            data: 'activateSignUpTermsAndConditions',
          },
        }),
        '*',
      )
      this.hideAbsoluteSection()
    }
  }

  private acceptDataPrivacy = (): void => {
    const iframe = this.iframe.current
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        JSON.stringify({
          activateSignUpDataPrivacy: {
            type: 'data',
            data: 'activateSignUpDataPrivacy',
          },
        }),
        '*',
      )
      this.hideAbsoluteSection()
    }
  }

  private renderAbsoluteSection = (): JSX.Element => {
    const { visibleSection } = this.state
    let title
    let content
    let action

    if (visibleSection === SignUpPageSection.termsAndConditions) {
      title = 'Terms and Conditions'
      content = termsAndConditions
      action = this.acceptTermsAndConditions
    } else {
      title = ''
      content = privacyNotice
      action = this.acceptDataPrivacy
    }

    return (
      <div className={cn('absolute-section')}>
        <div className={cn('absolute-section-content')}>
          <Title highlightFirstWord>{title}</Title>
          {content}
        </div>
        <ModalActionBar>
          <Button rounded uppercased buttonType="primary" onClick={action}>
            Accept
          </Button>
          <Button rounded uppercased onClick={this.hideAbsoluteSection}>
            Cancel
          </Button>
        </ModalActionBar>
      </div>
    )
  }

  public render(): JSX.Element {
    const { visibleSection, iframeUrl } = this.state
    return (
      <Dialog
        open
        center
        onClose={this.closePage}
        scroll="paper"
        maxWidth="md"
        classes={{
          paper: cn('container'),
        }}
      >
        <div className={cn('')}>
          <ModalHeader
            center
            title="Credit Solution Portal | Tietoevry Banking"
            onCloseClick={this.closePage}
          />
          <div className={cn('content')}>
            <IFrame
              refProp={this.iframe}
              src={iframeUrl}
              className={cn('iframe', {
                hidden: Boolean(visibleSection),
              })}
            />
            {visibleSection && this.renderAbsoluteSection()}
          </div>
        </div>
      </Dialog>
    )
  }
}

export class IFrame extends React.Component<any> {
  public shouldComponentUpdate(nextProps: any): boolean {
    const { src, className } = this.props
    if (src !== nextProps.src || className !== nextProps.className) {
      return true
    }

    return false
  }

  public render(): JSX.Element {
    const { refProp } = this.props
    return <iframe ref={refProp} {...this.props} />
  }
}
