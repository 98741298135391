import { theme } from '@/services/theme'
import { StyleSheet } from 'aphrodite/no-important'
import { createStyle, StyleService, paddingVertical, XS_INDENT, S_INDENT } from 'obap-core'

export const styles = StyleSheet.create({
  defaultLayout: createStyle({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    fontFamily: theme.fonts.corporate.regular,
  }),
  defaultLayoutHeader: createStyle({
    position: 'sticky',
    top: 0,
    zIndex: 3,
  }),
  mainSection: createStyle({
    flex: 1,
    display: 'flex',
    position: 'relative',
  }),
  navigationBar: createStyle({
    position: 'absolute',
    width: '100%',
    zIndex: -1,
    transition: 'all .2s ease',
  }),
  defaultLayoutContent: createStyle({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    zIndex: 1,
  }),
  expandedNavigationBar: createStyle({
    height: 46,
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    ':nth-child(n) > *': createStyle({
      height: 'inherit',
    }),
  }),
  expandedLogo: createStyle({
    fill: StyleService.colors.defaultTextColor,
  }),
  expandedMenuItem: createStyle({
    color: StyleService.colors.neutral.semidark,
    ':hover': createStyle({
      color: StyleService.colors.defaultTextColor,
    }),
  }),
  dropdownMenuContent: createStyle({
    position: 'absolute',
    backgroundColor: StyleService.colors.light,
    top: 40,
    width: 200,
    right: 0,
    left: 'auto',
    marginLeft: 0,
    transform: 'translateX(0)',
    boxShadow: '0 1px 2px rgba(0, 0, 0, .2), 0 0 1px rgba(0, 0, 0, .15)',
    ...paddingVertical(XS_INDENT),
  }),
  profileMenuItem: createStyle({
    position: 'relative',
  }),
  notificationBar: createStyle({
    position: 'fixed',
    width: '100%',
    zIndex: 1299,
    bottom: 0,

    ':nth-child(n) > *': createStyle({
      marginBottom: S_INDENT,
    }),
  }),
})
