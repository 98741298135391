import * as React from 'react'

import { HamburgerButton, Heading } from 'obap-core'

import { bem } from '@/utils/helpers/bem-legacy.helper'
import { ModalHeaderProps } from './types'
import './styles.scss'

const cn = bem('modal-header')

export class ModalHeader extends React.Component<ModalHeaderProps> {
  public render(): JSX.Element {
    const { title, onCloseClick, center } = this.props
    return (
      <header className={cn('')}>
        <Heading priority={2} className={cn('title', { center: Boolean(center) })}>
          {title}
        </Heading>
        {onCloseClick && <HamburgerButton active onClick={onCloseClick} />}
      </header>
    )
  }
}
