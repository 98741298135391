import { StyleSheet } from 'aphrodite/no-important'
import {
  createStyle,
  paddingMixed,
  M_INDENT,
  paddingVertical,
  XS_INDENT,
  L_INDENT,
  StyleService,
  paddingHorizontal,
  MEDIA_MAX_CONTENT_WIDTH_M,
} from 'obap-core'
export const styles = StyleSheet.create({
  cookieSettings: createStyle({
    ':nth-child(n) > div > div': createStyle({
      maxWidth: 800,
      minHeight: 'calc(500px - 54px)',
      maxHeight: 600,
      overflowY: 'auto',
    }),
    zIndex: 1300,
  }),
  cookieSettingsContent: createStyle({
    fontFamily: StyleService.fontFamily,
    maxWidth: 'none',
    minHeight: 0,
    maxHeight: 'none',
    overflowY: 'initial',
  }),
  cookieSettingsHeader: createStyle({
    ...paddingMixed(35, 35),
    paddingBottom: M_INDENT,
    borderBottom: '1px solid',
    borderBottomColor: '#D8D8D8D8',
    ':nth-child(n) > header': createStyle({
      paddingBottom: XS_INDENT,
    }),
    ':nth-child(n) > header > h3': createStyle({
      fontSize: 21,
      fontWeight: 600,
    }),
    ':nth-child(n) > header > button': createStyle({
      marginBottom: 15,
      marginLeft: 10,
    }),
  }),
  description: createStyle({
    lineHeight: '1.635rem',
    fontSize: 16,
    letterSpacing: '-.3px',
    marginTop: 0,
    marginBottom: 16,
    ':nth-child(n) a': createStyle({
      textDecoration: 'none',
      color: StyleService.colors.primary.dark,
      fontWeight: 600,
      marginLeft: 5,
      ':hover': {
        color: StyleService.colors.action,
      },
    }),
  }),
  cookieSettingsFooter: createStyle({
    display: 'flex',
    backgroundColor: StyleService.colors.neutral.light,
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    ...paddingVertical(20),
    ...paddingHorizontal(20),
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      flexDirection: 'column',
    }),
  }),
  button: createStyle({
    fontFamily: StyleService.fontFamily,
    fontWeight: 500,
    lineHeight: '22px',
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      minWidth: 350,
      marginBottom: XS_INDENT,
      ':last-child': createStyle({
        marginBottom: 0,
      }),
    }),
  }),
  dropdownTitle: createStyle({
    fontSize: 18,
    lineHeight: 1,
    marginBottom: XS_INDENT,
    paddingBottom: XS_INDENT,
  }),
  dropdownDescription: createStyle({}),
  dropdownSolutionsList: createStyle({
    marginTop: L_INDENT,
    ':nth-child(n) > span': createStyle({
      fontWeight: 600,
    }),
  }),
})
