import * as React from 'react'
import * as uuid from 'uuid'

import { Dialog } from '@/components/blocks/legacy/dialog'
import { ModalHeader } from '@/components/blocks/legacy/modal-header'

import { KEYCLOAK_FORGOT_PASSWORD_URL, FORGOT_PASSWORD_PATH, REALM_ID } from '@/utils/constants'

import { queryObjectToString, bem } from '@/utils/helpers'

import { ForgotPasswordPageProps } from './types'
import './styles.scss'

const cn = bem('forgot-password-page')

export class ForgotPasswordPage extends React.Component<ForgotPasswordPageProps> {
  private closePage = (): void => {
    const {
      history: { push },
      location: { pathname },
    } = this.props
    const appEndpoint = pathname.replace(FORGOT_PASSWORD_PATH, '')
    push(appEndpoint)
  }

  private generateForgotPasswordUrl = (): string => {
    const baseUrl = KEYCLOAK_FORGOT_PASSWORD_URL.replace('{realmId}', REALM_ID)
    const params = {
      client_id: 'public_client', // eslint-disable-line
      redirect_uri: '/', // eslint-disable-line
      state: uuid.v1(),
      response_mode: 'fragment', // eslint-disable-line
      response_type: 'code', // eslint-disable-line
      scope: 'openid',
    }

    return `${baseUrl}${queryObjectToString(params)}`
  }

  public render(): JSX.Element {
    return (
      <Dialog open center onClose={this.closePage} scroll="paper" maxWidth="md">
        <div className={cn('')}>
          <ModalHeader
            center
            title="Credit Solution Portal | Tietoevry Banking"
            onCloseClick={this.closePage}
          />
          <iframe src={this.generateForgotPasswordUrl()} width="100%" height="600px" />
        </div>
      </Dialog>
    )
  }
}
