/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/ban-types */
//FIXME: export types to global types and fux eslint issues
import * as React from 'react'
import toast from 'react-hot-toast'
import { useHistory, useLocation } from 'react-router-dom'
enum IframeMessageType {
  ConsentLoaded = 'consentLoaded',
  ReadyToGetLoginData = 'login',
  LoginData = 'loginData',
  ConsentConfirmed = 'consentConfirmed',
  ConsentRejected = 'consentCanceled',
  TransferConfirmed = 'transferConfirmed',
  TransferRejected = 'transferCanceled',
  FundsConfirmationConsentConfirmed = 'fundsConfirmationConsentConfirmed',
  FundsConfirmationConsentCanceled = 'fundsConfirmationConsentCanceled',
  Notification = 'globalNotification',
  NavigationEvent = 'navigation',
  ConsentAction = 'consentAction',
}

interface IframeMessage<DataType> {
  type: IframeMessageType
  date: DataType
}

enum NotificationType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

interface NotificationOptions {
  type: NotificationType
  label: string
  content: string
  duration?: number
}

export const KeycloakNotificationsProvider = (): null => {
  const { pathname } = useLocation()
  const { push } = useHistory()

  const onGlobalNotificationRecieved = (data: NotificationOptions): void => {
    switch (data.type) {
      case 'success':
        toast.success(<div dangerouslySetInnerHTML={{ __html: data?.content }} />, {
          duration: data.duration || 3500,
          position: 'bottom-left',
        })
        break
      case 'error':
        toast.error(<div dangerouslySetInnerHTML={{ __html: data?.content }} />, {
          duration: data.duration || 3500,
          position: 'bottom-left',
        })
    }
  }
  const onNavigationNotificationRecieved = React.useCallback(
    (path: string): void => {
      const lastPartIndex = pathname.lastIndexOf('/')
      let prevPath = ''

      if (lastPartIndex >= 0) {
        prevPath = pathname.slice(0, lastPartIndex)
      }

      push(`${prevPath}${path}`)
    },
    [pathname, push],
  )

  const handleIframeMessages = React.useCallback(
    (message: MessageEvent): void => {
      if (message && typeof message.data === 'string') {
        try {
          const messageData: IframeMessage<string | NotificationOptions> = JSON.parse(message.data)
          Object.keys(messageData).forEach((key: string) => {
            //@ts-expect-error type addition needed
            const notificationContent = messageData[key]
            switch (notificationContent.type) {
              case 'alert':
                onGlobalNotificationRecieved({
                  type: notificationContent.data.type,
                  label: notificationContent.data.type,
                  content: notificationContent.data.data,
                })
                break
              case IframeMessageType.NavigationEvent:
                onNavigationNotificationRecieved(notificationContent.data as string)
                break
            }
          })
        } catch (error) {
          error
        }
      }
    },
    [onNavigationNotificationRecieved],
  )
  React.useEffect(() => {
    window.addEventListener('message', handleIframeMessages)
    return () => {
      window.removeEventListener('message', handleIframeMessages)
    }
  }, [handleIframeMessages])

  return null
}
