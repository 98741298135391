import * as React from 'react'

import { useLocation } from 'react-router-dom'
import { cn, NavigationBar as CoreNavBar, NavigationBarMenu, useKeycloak } from 'obap-core'
import { mainLinks, authLinks } from './navigation-links'

import { getClearPathname } from '@/utils/helpers'
import { AuthRequiredEntity, NavigationBarState } from './navigation-bar.types'
import { styles } from './navigation-bar.styles'

const onlyAuthFilter = (auth: boolean) => (entity: AuthRequiredEntity) =>
  entity.authRequired === undefined ||
  (auth && entity.authRequired && typeof entity.authRequired === 'boolean')

const onlyPublicFilter = (auth: boolean) => (entity: AuthRequiredEntity) =>
  entity.authRequired === undefined ||
  (!auth && !entity.authRequired && typeof entity.authRequired === 'boolean')

export const NavigationBar = (props: {
  onExpanded: (state: NavigationBarState) => void
}): JSX.Element => {
  const { onExpanded } = props
  const { pathname } = useLocation()

  const [username, setUsername] = React.useState<string | undefined>()
  const { firstName, lastName, logout } = useKeycloak()

  React.useEffect(() => {
    if (firstName && lastName) {
      setUsername(`${firstName} ${lastName}`)
    } else {
      setUsername(undefined)
    }
  }, [pathname, firstName, lastName])

  const [main, auth] = React.useMemo(() => {
    const auth = Boolean(username)
    const filter = auth ? onlyAuthFilter(auth) : onlyPublicFilter(auth)
    const mainSection = mainLinks.filter(filter as any)
    const authSection = authLinks(logout, getClearPathname(pathname), username).filter(filter)
    return [mainSection, authSection]
  }, [username, pathname, logout])

  return (
    <CoreNavBar onExpanded={onExpanded as any}>
      <div className={cn(styles.navigationMenu)}>
        <NavigationBarMenu navigationItems={main} />
      </div>
      <div className={cn(styles.secondaryNavigationMenu)}>
        <NavigationBarMenu navigationItems={auth} />
      </div>
    </CoreNavBar>
  )
}
