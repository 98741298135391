import { Toaster } from 'react-hot-toast'
import { theme } from '@/services/theme'

import { StyleSheet } from 'aphrodite/no-important'
import { createStyle, cn } from 'obap-core'

export const styles = StyleSheet.create({
  toast: createStyle({
    fontFamily: theme.fonts.corporate.regular,
  }),
})

const notificationOptions = {
  className: cn(styles.toast),
  error: {
    // style: {
    //   fontFamily: theme.fonts.corporate.regular,
    // },
  },
}
export const NotificationProvider = () => {
  return <Toaster toastOptions={notificationOptions} />
}
