import { cn, TietoLogo } from 'obap-core'

import { ContentContainer } from '@/components/blocks/content-container'

// import { TERMS_AND_CONDITIONS_PATH, PRIVACY_NOTICE_PATH } from '@/constants'

import { ReactComponent as IconTwitter } from '@/assets/social-icons/Twitter.svg'
import { ReactComponent as IconYouTube } from '@/assets/social-icons/YouTube.svg'
import { ReactComponent as IconFacebook } from '@/assets/social-icons/Facebook.svg'
import { ReactComponent as IconLinkedIn } from '@/assets/social-icons/LinkedIn.svg'

import { FooterList, SocialIcon } from './general-footer.types'
import { styles } from './general-footer.styles'

export const socialIconList: SocialIcon[] = [
  {
    url: 'https://www.linkedin.com/company/tietoevry',
    icon: (
      <div className={cn(styles.linkedin)}>
        <IconLinkedIn />
      </div>
    ),
  },
  {
    url: 'https://www.facebook.com/TietoEVRY',
    icon: (
      <div className={cn(styles.facebook)}>
        <IconFacebook />
      </div>
    ),
  },
  {
    url: 'https://twitter.com/TietoEVRY',
    icon: (
      <div className={cn(styles.twitter)}>
        <IconTwitter />
      </div>
    ),
  },
  {
    url: 'https://www.youtube.com/c/TietoEVRY',
    icon: (
      <div className={cn(styles.youtube)}>
        <IconYouTube />
      </div>
    ),
  },
]

const footerLinks: FooterList[] = [
  //TODO: add terms and conditions & privacy notice
  {
    title: 'Information',
    links: [
      // {
      //   label: 'Terms and Conditions',
      //   pathname: 'TERMS_AND_CONDITIONS_PATH',
      // },
      // {
      //   label: 'Data Privacy Notice',
      //   pathname: 'PRIVACY_NOTICE_PATH',
      // },
      {
        label: 'Information for suppliers',
        pathname: 'https://www.tietoevry.com/en/information-for-suppliers/',
      },
    ],
  },
  {
    title: 'Discover',
    links: [
      {
        label: 'About us',
        pathname: 'https://www.tietoevry.com/en/about-us/our-company/',
      },
      {
        label: 'Sustainability',
        pathname: 'https://www.tietoevry.com/en/sustainability/',
      },
      {
        label: 'Contact us',
        pathname: 'https://www.tietoevry.com/en/contact-tietoevry/',
      },
    ],
  },
]

const currentYear = new Date().getFullYear()

export const GeneralFooter = (): JSX.Element => {
  return (
    <footer className={cn(styles.footer)}>
      <ContentContainer blockStyle={styles.container}>
        <div className={cn(styles.contentContainer, styles.primaryContentContainer)}>
          <div>
            <TietoLogo blockStyle={styles.logo} />
            <p className={cn(styles.text, styles.description)}>
              Creating purposeful technology that reinvents the world for good
            </p>
          </div>
        </div>
        {footerLinks.map((list, index) => (
          <div
            className={cn(styles.contentContainer, styles.secondaryContentContainer)}
            key={`footer-list-${index}`}
          >
            <div className={cn(styles.listTitle)}>{list.title}</div>
            <ul>
              {list.links.map((link, index) => (
                <li key={`footer-link-${index}`} className={cn(styles.listItem)}>
                  <a href={link.pathname} className={cn(styles.text, styles.link)}>
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
        <div className={cn(styles.contentContainer, styles.primaryContentContainer)}>
          <div className={cn(styles.socialContainer)}>
            {socialIconList.map(({ url, icon }) => (
              <a
                key={url}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.socialLink)}
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </ContentContainer>
      <ContentContainer blockStyle={[styles.container, styles.copyrightContainer]}>
        <div className={cn(styles.contentContainer, styles.secondaryContentContainer)}>
          <div className={cn(styles.copyright)}>{`@Tietoevry ${currentYear}`}</div>
        </div>
      </ContentContainer>
    </footer>
  )
}
