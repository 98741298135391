import { ValidatorHelper } from '@/utils/helpers'
import { COOKIE_NAME, CookiesData } from '@/types'
import { FormValidatorType } from './cookie-settings-form.types'

const formValidationConfig: FormValidatorType = {
  [COOKIE_NAME.necessary]: {
    comparator: (values: CookiesData): any => {
      const errors = {}
      if (!values[COOKIE_NAME.necessary]) {
        //@ts-expect-error ts fun
        errors[COOKIE_NAME.necessary] = 'Necessary cookies disabled'
      }
      return errors
    },
  },
}

export const validateCookieSettingsForm = (values: CookiesData) =>
  ValidatorHelper.commonFormValidator<CookiesData, FormValidatorType>(values, formValidationConfig)
