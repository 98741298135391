import { StyleSheet } from 'aphrodite/no-important'
import {
  createStyle,
  MEDIA_MIN_CONTENT_WIDTH_L,
  MEDIA_MIN_CONTENT_WIDTH_M,
  MEDIA_MIN_CONTENT_WIDTH_XL,
} from 'obap-core'
export const styles = StyleSheet.create({
  container: createStyle({
    width: '100%',
    boxSizing: 'border-box',
    paddingRight: 15,
    paddingLeft: 15,
    //extra small
    ['@media (min-width: 575.98px)']: createStyle({
      maxWidth: 540,
    }),
    [MEDIA_MIN_CONTENT_WIDTH_M]: createStyle({
      maxWidth: 720,
    }),
    [MEDIA_MIN_CONTENT_WIDTH_L]: createStyle({
      maxWidth: 960,
    }),
    [MEDIA_MIN_CONTENT_WIDTH_XL]: createStyle({
      maxWidth: 1140,
    }),
    //extra large+
    ['@media (min-width: 1599.98px)']: createStyle({
      maxWidth: 1300,
    }),
    ['@media (min-width: 1799.98px)']: createStyle({
      maxWidth: 1400,
    }),
  }),
})
