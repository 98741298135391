import { cn } from 'obap-core'
import { FORGOT_PASSWORD_PATH } from '@/utils/constants'
import { styles } from './notifications.styles'

export const Notification401 = () => {
  return (
    <div className={cn(styles.invalidCredentials)}>
      <p>
        Incorrect username or password
        <br />
        Please, use
        <a className={cn(styles.link)} href={FORGOT_PASSWORD_PATH}>
          &nbsp;reset password&nbsp;
        </a>
        for recovering credentials
      </p>
    </div>
  )
}
