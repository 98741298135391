import * as React from 'react'
import { Route, RouteWrapper, ACCESS_MODE } from 'obap-core'
import {
  MAIN_PAGE_ROUTE,
  API_PORTALS_PATH,
  UNIVERSAL_ROUTE,
  DOCUMENTATION_ROUTE,
  DOCUMENTATION_SIGN_IN_PATH,
  DOCUMENTATION_SIGN_UP_PATH,
  DOCUMENTATION_FORGOT_PASSWORD_PATH,
  SIGN_IN_APP_ROUTE,
  USER_PROFILE_ROUTE,
  SIGN_UP_APP_ROUTE,
  FORGOT_PASSWORD_APP_ROUTE,
  COOKIE_SETTINGS_APP_ROUTE,
} from '@/utils/constants/'
import { WorkplaceSection } from '@/components/sections'
import { SignInPage } from '@/components/pages/sign-in-page'
import { SignUpPage } from '@/components/pages/sign-up-page'
import { ForgotPasswordPage } from '@/components/pages/forgot-password-page'
import { CookieSettingsPage } from '@/components/pages/cookie-settings-page'

const EmptyLayout = React.lazy(() =>
  import(/* webpackChunkName: "empty-layout" */ '@/components/layouts/empty-layout').then(
    module => ({ default: module.EmptyLayout }),
  ),
)
const WorkplaceLayout = React.lazy(() =>
  import(
    /* webpackChunkName: "workplace-layout" */ '@/components/layouts/side-menu-layout/workplace-layout'
  ).then(module => ({ default: module.WorkplaceLayout })),
)

const NotFoundPage = React.lazy(() =>
  import(/* webpackChunkName: "not-found-page" */ '@/components/pages/not-found-page').then(
    module => ({ default: module.NotFoundPage }),
  ),
)
const LandingPage = React.lazy(() =>
  import(/* webpackChunkName: "landing-page" */ '@/components/pages/landing-page').then(module => ({
    default: module.LandingPage,
  })),
)
const ApiPortalsPage = React.lazy(() =>
  import(/* webpackChunkName: "api-portals-page" */ '@/components/pages/api-portals-page').then(
    module => ({ default: module.ApiPortalsPage }),
  ),
)

const DocumentationPage = React.lazy(() =>
  import(
    /* webpackChunkName: "documentation-list-page" */
    '@/components/pages/documentation-page'
  ).then(module => ({ default: module.DocumentationPage })),
)

const UserProfilePage = React.lazy(() =>
  import(
    /* webpackChunkName: "user-profile-page" */
    '@/components/pages/tabs-page'
  ).then(module => ({ default: module.UserProfilePage })),
)

export const MODAL_ROUTES: Route[] = [
  {
    component: SignInPage,
    path: SIGN_IN_APP_ROUTE,
    accessMode: ACCESS_MODE.ONLY_PUBLIC,
    modal: true,
  },
  {
    component: SignUpPage,
    path: SIGN_UP_APP_ROUTE,
    accessMode: ACCESS_MODE.ONLY_PUBLIC,
    modal: true,
  },
  {
    component: ForgotPasswordPage,
    path: FORGOT_PASSWORD_APP_ROUTE,
    accessMode: ACCESS_MODE.ONLY_PUBLIC,
    modal: true,
  },
  {
    component: CookieSettingsPage,
    path: COOKIE_SETTINGS_APP_ROUTE,
    accessMode: ACCESS_MODE.DEFAULT,
    modal: true,
  },
]

export const ROUTES: (Route | RouteWrapper)[] = [
  {
    component: DocumentationPage,
    path: [
      DOCUMENTATION_ROUTE,
      DOCUMENTATION_SIGN_IN_PATH,
      DOCUMENTATION_SIGN_UP_PATH,
      DOCUMENTATION_FORGOT_PASSWORD_PATH,
    ],
    accessMode: ACCESS_MODE.DEFAULT,
  },
  {
    component: EmptyLayout,
    children: [
      {
        component: ApiPortalsPage,
        path: API_PORTALS_PATH,
        accessMode: ACCESS_MODE.DEFAULT,
      },
    ],
  },
  {
    component: WorkplaceLayout,
    children: [
      {
        component: WorkplaceSection,
        children: [
          {
            component: UserProfilePage,
            path: USER_PROFILE_ROUTE,
            accessMode: ACCESS_MODE.PRIVATE,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    component: LandingPage,
    path: MAIN_PAGE_ROUTE,
    accessMode: ACCESS_MODE.DEFAULT,
    exact: true,
  },
  {
    component: EmptyLayout,
    children: [
      {
        component: NotFoundPage,
        path: UNIVERSAL_ROUTE,
        accessMode: ACCESS_MODE.DEFAULT,
      },
    ],
  },
]
