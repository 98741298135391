import * as React from 'react'

import { bem } from '@/utils/helpers/bem-legacy.helper'
import './styles.scss'

const cn = bem('modal-action-bar')

export const ModalActionBar: React.FC = ({ children }): JSX.Element => {
  return <div className={cn('')}>{children}</div>
}
