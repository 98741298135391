import * as React from 'react'
import CoreDialog from '@material-ui/core/Dialog'

import { bem, className as cnGenerator } from '@/utils/helpers/bem-legacy.helper'

import { DialogProps, DialogState } from './types'
import './styles.scss'

const cn = bem('dialog')

const requiredClasses = ['root', 'paper']

export class Dialog extends React.Component<DialogProps, DialogState> {
  public render(): JSX.Element {
    const { classes, center, children, ...restProps } = this.props

    const classesArray = {}

    if (classes) {
      requiredClasses.forEach((className: string) => {
        // eslint-disable-next-line
        if (classes.hasOwnProperty(className)) {
          // @ts-expect-error fix later
          classesArray[className] = classes[className]
          // @ts-expect-error fix later
          delete classes[className]
        }
      })
    }

    return (
      <CoreDialog
        {...restProps}
        classes={{
          // @ts-expect-error fix later
          root: cnGenerator(cn({ center: Boolean(center) }), classesArray['root']),
          // @ts-expect-error fix later
          paper: cnGenerator(cn('container'), classesArray['paper']),
          paperWidthMd: cn('paperWidthMd'),
          paperWidthSm: cn('paperWidthSm'),
          paperWidthXs: cn('paperWidthXs'),
          ...classes,
        }}
      >
        {children}
      </CoreDialog>
    )
  }
}
