import { StyleSheet } from 'aphrodite/no-important'
import {
  createStyle,
  paddingVertical,
  S_INDENT,
  M_INDENT,
  L_INDENT,
  XS_INDENT,
  StyleService,
  paddingHorizontal,
} from 'obap-core'

export const styles = StyleSheet.create({
  expansionPanelRoot: createStyle({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #C9D2D7',
    ':first-of-type': createStyle({
      marginTop: '-10px',
    }),
  }),
  expansionPanelRootIcon: createStyle({
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #C9D2D7',
    ':first-of-type': createStyle({
      marginTop: 0,
    }),
  }),
  labelWrapper: createStyle({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',
    height: 54,
    ...paddingVertical(XS_INDENT),
    ...paddingHorizontal(L_INDENT),
  }),
  separateLine: createStyle({
    borderBottom: '1px solid #C9D2D7',
    justifyContent: 'center',
    ':last-of-type': createStyle({
      borderBottom: 'unset',
    }),
  }),
  iconExpand: createStyle({
    fontSize: 18,
    transform: 'rotate(-90deg)',
    fill: 'rgba(64, 64, 64, .5)',
    transition: 'transform .2s ease',
    marginLeft: '-5px',
    marginRight: 8,
  }),
  iconExpandActive: createStyle({
    transform: 'rotate(0deg)',
  }),
  title: createStyle({
    margin: 0,
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 400,
    fontSize: M_INDENT,
  }),
  content: createStyle({
    width: '100%',
    display: 'none',
    paddingLeft: 28,
    paddingBottom: S_INDENT,
  }),
  contentActive: createStyle({
    display: 'block',
  }),
  contentActiveNoPadding: createStyle({
    padding: 0,
  }),
  expandedPanel: createStyle({
    background: StyleService.colors.neutral.light,
  }),
  iconExpandRight: createStyle({
    fontSize: 18,
    transform: 'rotate(-90deg)',
    fill: 'rgba(64, 64, 64, .5)',
    transition: 'transform .2s ease',
    marginLeft: 'auto',
  }),
})
