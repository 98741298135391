import { COOKIE_NAME } from '@/types'

export const cookieDescription =
  "Cookies are small piece of data sent from a website and stored on the user's computer by the user's web browser while the user is browsing. Your browser stores each message in a small file, called cookie. When you request another page from the server, your browser sends the cookie back to the server. Cookies were designed to be a reliable mechanism for websites to remember information or to record the user's browsing activity." // eslint-disable-line
export const cookieSettingsConfig = [
  {
    typeOfCookie: COOKIE_NAME.necessary,
    required: true,
    title: 'Strictly necessary Cookies',
    description:
      'These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.', // eslint-disable-line
    solutions: [
      'CloudFlare',
      'All our own cookies',
      'Google Tag Manager',
      'Application Insights - User',
      'Application Insights - Session',
    ],
  },
  {
    typeOfCookie: COOKIE_NAME.functional,
    required: false,
    title: 'Functional',
    description: 'This is needed for analytics to function on the site.',
    solutions: ['Google Analytics'],
  },
]

export const initialCookieSettings = {
  [COOKIE_NAME.necessary]: true,
  [COOKIE_NAME.functional]: true,
}
