import { StyleSheet } from 'aphrodite/no-important'
import { createStyle } from 'obap-core'
export const styles = StyleSheet.create({
  visible: createStyle({
    display: 'block',
    width: '100%',
  }),
  hidden: createStyle({
    display: 'none',
  }),
})
