import { theme } from '@/services/theme'
import { StyleSheet } from 'aphrodite/no-important'
import { cn, Heading, M_INDENT } from 'obap-core'

const openCookieDoc = () => {
  window.open('https://www.tieto.com/en/privacy-notice/cookie-notice/')
}

const openPrivacyNotice = () => {
  window.open('https://www.tieto.com/en/privacy-notice/')
}

const styles = StyleSheet.create({
  information: {
    fontFamily: theme.fonts.corporate.regular,
    width: '100%',
    ':nth-child(n) > *': {
      marginBottom: M_INDENT,
    },
    ':nth-child(n) ul': {
      paddingLeft: M_INDENT,
    },
  },
})

export const privacyNotice = (
  <section className={cn(styles.information)}>
    <Heading priority={2}>Scope of this Privacy Notice</Heading>
    <p>
      This privacy notice aims to give you information on how Tieto collects and processes your
      personal data and tells you about your privacy rights and how the law protects you, when you
      use
      <strong>Tietoevry Banking Credit Solutions</strong>.
    </p>
    <Heading priority={2}>What data we collect about you?</Heading>
    <p>
      Personal data, or personal information, means any information about an individual from which
      that person can be identified. It does not include data where the identity has been removed
      (anonymous data).
    </p>
    <div>
      <p>
        We collect, use, store and transfer different kinds of personal data about you which we have
        grouped together follows:
      </p>
      <ul>
        <li>
          <p>
            <strong>Identity Data</strong>
            such as first name, last name, username or similar identifier.
          </p>
        </li>
        <li>
          <p>
            <strong>Contact Data</strong>
            such as address, email address and telephone numbers.
          </p>
        </li>
        <li>
          <p>
            <strong>Employment Data</strong>
            such as job title, company name, department name, role classification.
          </p>
        </li>
        <li>
          <p>
            <strong>Profile Data</strong>
            such as your username and password, your interests, preferences, feedback and survey
            responses.
          </p>
        </li>
        <li>
          <p>
            <strong>Usage Data</strong>
            such as information about how you use our website, products and services.
          </p>
        </li>
        <li>
          <p>
            <strong>Marketing and Communications Data</strong>
            such as your preferences in receiving marketing from us and our third parties and your
            communication preferences.
          </p>
        </li>
        <li>
          <p>
            <strong>Sales and Customer Relationship Data</strong>
            such as role in the company, access rights, offering details and activity.
          </p>
        </li>
        <li>
          <p>
            <strong>
              Technical Data such as Internet protocol (IP) address, your login data, browser type
              and version, time zone setting and location, browser plug-in types and versions,
              operating system and platform and other technology on the devices you use to access
              this website.
            </strong>
          </p>
        </li>
      </ul>
    </div>
    <p>
      We also collect, use and share Aggregated Data such as statistical or demographic data for any
      purpose. Aggregated Data may be derived from your personal data but is not considered personal
      data in law as this data does not directly or indirectly reveal your identity. For example, we
      may aggregate your Usage Data to calculate the percentage of users accessing a specific
      website feature. However, if we combine or connect Aggregated Data with your personal data so
      that it can directly or indirectly identify you, we treat the combined data as personal data
      which will be used in accordance with this privacy notice.
    </p>
    <p>
      We do not generally collect any special categories of personal data about you (this includes
      details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
      orientation, political opinions, trade union membership, information about your health and
      genetic and biometric data). Nor do we collect any information about criminal convictions and
      offences. In the limited cases where we do process such data, we do this in accordance with
      the applicable legislation.
    </p>
    <Heading priority={2}>How is your personal information collected?</Heading>
    <div>
      <p>We use different methods to collect data from and about you including through:</p>
      <ul>
        <li>
          <p>
            <strong>Direct interactions</strong>. You may give us your personal information by
            filling in forms or by corresponding with us by post, phone, email or otherwise.
          </p>
        </li>
        <li>
          <p>
            <strong>Automated technologies or interactions.</strong>
            As you interact with our website, we may automatically collect Technical Data about your
            equipment, browsing actions and patterns. We collect this personal data by using
            cookies, server logs, data analytics tools and other similar technologies. We may also
            receive Technical Data about you if you visit other websites employing our cookies.
            <a className="link" onClick={openCookieDoc}>
              Please see our Cookie notice for further details
            </a>
            .
          </p>
        </li>
      </ul>
    </div>
    <p>
      Please read also a specific privacy notice to the general privacy notice (
      <a className="link" onClick={openPrivacyNotice}>
        https://www.tieto.com/en/privacy-notice/
      </a>
      ).
    </p>
    <div>
      <p>
        <strong>Third parties or publicly available sources</strong>. We may receive personal data
        about you from various third parties and public sources as set out below:
      </p>
      <ul>
        <li>
          <p>
            <strong>Identity, Contact, and Employment Data</strong>
            from third parties (including data brokers or aggregators) or from publicly available
            sources (e.g. published articles, press releases, your public posts on social media
            sites if relevant for business purpose).
          </p>
        </li>
      </ul>
    </div>
    <Heading priority={2}>For what purposes is your personal information processed?</Heading>
    <p>
      We have set out below, in a table format, a description of all the ways we plan to use your
      personal data, and which of the legal bases we rely on to do so. We have also identified what
      our legitimate interests are where appropriate.
    </p>
    <p>
      Note that we may process your personal data for more than one lawful ground depending on the
      specific purpose for which we are using your data. Please contact us at privacy@tieto.com if
      you need details about the specific legal ground we are relying on to process your personal
      data where more than one ground has been set out in the table below.
    </p>
    <div>
      <p>
        <strong>Purpose/Activity</strong>
      </p>
      <ul>
        <li>
          <p>Lawful basis for processing including basis of legitimate interest</p>
        </li>
      </ul>
    </div>
    <div>
      <p>
        <strong>Managing the contractual relationship:</strong>
      </p>
      <ul>
        <li>
          <p>Creating and managing your Account, subscriptions and orders</p>
        </li>
        <li>
          <p>
            Verifying your identity and entitlement to products and services, and allowing the
            registration
          </p>
        </li>
        <li>
          <p>Updating the status of your order and managing subscriptions</p>
        </li>
      </ul>
    </div>
    <div>
      <p>
        <strong>Providing customer support</strong>
      </p>
      <ul>
        <li>
          <p>
            On the basis of fulfilling our contract with you or entering into a contract with you on
            your request (in case you use the Service as a natural person)
          </p>
        </li>
      </ul>
    </div>
    <p>
      Necessary for our legitimate interests (for managing our customer relationship, and for the
      purposes of satisfying any legal, accounting, or reporting requirements) (in case the Service
      is used by a legal entity)
    </p>
    <div>
      <p>
        <strong>Sales and Customer relationship management, including:</strong>
      </p>
      <ul>
        <li>
          <p>implementing, developing and maintaining business relationship with our customer</p>
        </li>
        <li>
          <p>asking you to leave a review or take a survey</p>
        </li>
        <li>
          <p>providing customer support</p>
        </li>
        <li>
          <p>managing reporting and contracts</p>
        </li>
      </ul>
    </div>
    <p>
      Necessary for our legitimate interests (for managing our customer relationship, and for the
      purposes of satisfying any legal, accounting, or reporting requirements)
    </p>
  </section>
)
