import { StyleSheet } from 'aphrodite/no-important'
import { cn, Heading, M_INDENT } from 'obap-core'

import { PRIVACY_NOTICE_PATH } from '@/utils/constants'
import { theme } from '@/services/theme'

const styles = StyleSheet.create({
  information: {
    fontFamily: theme.fonts.corporate.regular,
    width: '100%',
    ':nth-child(n) > *': {
      marginBottom: M_INDENT,
    },
    ':nth-child(n) ul': {
      paddingLeft: M_INDENT,
    },
  },
})

const openPrivacyNotice = () => {
  window.open(PRIVACY_NOTICE_PATH)
}

export const termsAndConditions = (
  <section className={cn(styles.information)}>
    <Heading priority={2}>Notice for users of this service</Heading>
    <p>
      The
      <strong>Tietoevry Banking Credit Solutions</strong>
      (“Service”) is provided by Tieto Latvia and/or its’ Affiliates (“Tieto”, “we”, “us”).
    </p>
    <p>
      Accessing and using the Service involves the full acceptance of the following Terms and
      Conditions. In case you do not accept these Terms and Conditions, do not continue to access or
      use the Service.
    </p>
    <p>
      If you are using the Service on behalf of a legal entity, you represent that you have full
      authorization and powers in binding the entity to this Agreement (“you” shall mean in this
      context the respective legal entity).
    </p>
    <Heading priority={2}>Access and use of the Service</Heading>
    <p>
      <strong>Your Account.</strong>
      You may need your own account to use this Service, and you may be required to be logged in to
      the account. You are responsible for maintaining the confidentiality of your account and
      password and for restricting access to your account, and you agree to accept responsibility
      for all activities that occur under your account or password. You need to maintain your
      technical environment capable for receiving the Service.
    </p>
    <p>
      <strong>Your data and use of the Service.</strong>
      <br />
      You shall retain the ownership to data you enter to the Service. The Service is not designed
      for data that could be considered as personal data nor for confidential or sensitive data. You
      agree not to enter any such data to the Service or any material that contains viruses, or
      unlawful or harmful material.
    </p>
    <p>
      You grant Tieto a non-exclusive right to process the data to provide the Service and related
      analytics.Tieto may use information derived from your use of the service to create analyses.
      Analyses will anonymize and aggregate information for purposes such as optimization of the
      service, research and development, automation of processes, developing Tieto’s new services,
      verification of security and data integrity, internal demand planning, industry analysis and
      benchmarking.
    </p>
    <p>
      <strong>Limitation on use.</strong>
      You shall be granted a non-exclusive, non-transferable, limited right to access and use the
      Service solely for internal informational purposed about
      <strong>Tieto Open API Hub</strong>
      offer. You agree not to share received information with any third parties.
    </p>
    <p>
      <strong>Suspension of Service.</strong>
      The Service may be suspended if your use harms the Service or other users, or if you do not
      comply with this Agreement.
    </p>
    <p>
      <strong>Changes to the Service.</strong>
      We retain the right to make changes or withdraw access to the Service, including full
      termination of the Service, in our sole discretion.
    </p>
    <p>
      <strong>Service breaks and interruptions.</strong>
      The Service may be subject to planned or unexpected service breaks.
    </p>
    <p>
      <strong>Customer Support.</strong>
      To find more information about our service and its features or if you need assistance with
      your account, please contact by email support.lv@tieto.com. The information provided through
      Customer Support shall be subject to these Terms and Conditions.
    </p>
    <p>
      <strong>Electronic Communications.</strong>
      We will send you information relating to your Account in electronic form only via emails to
      your email address provided during registration. You agree that all agreements, notices,
      disclosures, and other communications that we provide to you may be provided electronically.
    </p>
    <Heading priority={2}>Fees and Payment.</Heading>
    <p>The Service is provided free of charge.</p>
    <Heading priority={2}>Proprietary rights - Copyright, Logos and trademarks</Heading>
    <p>
      All software, components and other material shall remain the sole and exclusive property of
      Tieto or its’ current licensor(s). The s, including the contents as well as the selection and
      arrangement of the contents of each individual page, of the collection of the pages, and of
      the data or other materials accessible via the Service, are fully owned by Tieto (i.e. Tieto
      Corporation and its subsidiaries and affiliates).
    </p>
    <p>
      You are allowed to store extracts from the Service on your computer and to print copies taking
      into account the
      <strong>Limitation on use.</strong>
      Any other kind of use, reproduction, translation, adaptation, arrangement, any other
      alteration, distribution or storage of Tieto´s pages in any form and by any means, in whole or
      in part without the prior written permission of Tieto is prohibited.
    </p>
    <p>
      The Logos and Tieto´s product names are trademarks of Tieto Corporation. If you download any
      information or material from this site, you agree that you will not copy it or remove or
      obscure any copyright or other notices or legends contained in any such information.
    </p>
    <Heading priority={2}>Disclaimer and limitation of liability</Heading>
    <p>
      THE SERVICE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER
      SERVICE INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE ARE PROVIDED BY
      TIETO ON AN &#34;AS IS&#34; AND &#34;AS AVAILABLE&#34; BASIS, UNLESS OTHERWISE SPECIFIED IN
      WRITING. TIETO MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
      THE OPERATION OF THE SERVICE, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
      SOFTWARE) OR OTHER SERVICE INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE,
      UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT
      YOUR SOLE RISK.
    </p>
    <p>
      TO THE FULL EXTENT PERMISSIBLE BY LAW, TIETO DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
      INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
      PARTICULAR PURPOSE. TIETO DOES NOT WARRANT THAT THE SERVICE, INFORMATION, CONTENT, MATERIALS,
      PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICE INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU
      THROUGH THE SERVICE, TIETO&#39;S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM TIETO ARE
      ERROR-FREE, FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY
      LAW, TIETO WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY PART OF
      THE SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR
      OTHER SERVICE INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE, INCLUDING,
      BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS
      OTHERWISE SPECIFIED IN WRITING.
    </p>
    <Heading priority={2}>Data protection</Heading>
    <p>
      Tieto is committed to respecting the privacy rights of individuals. Please further details on
      the processing of your personal data in our (
      <a className="link" onClick={openPrivacyNotice}>
        Data Privacy Notice
      </a>
      ).
    </p>
    <Heading priority={2}>Miscellaneous.</Heading>
    <p>
      These Terms and Conditions shall be governed by and construed in accordance with the laws of
      Finland, excluding its choice of laws principle. If you are a natural person, any dispute,
      controversy or claim will be settled by the district court in Helsinki, Finland in accordance
      with the laws of Finland. If you represent a legal entity, any dispute, controversy or claim
      shall be finally settled by arbitration in Helsinki, Finland under the Rules of the
      Arbitration Institute of Finland Chamber of Commerce.
    </p>
    <p>
      If any provision or provisions of these Terms and Conditions shall be held to be invalid,
      illegal, or unenforceable, the validity, legality and enforceability of the remaining
      provisions shall remain in full force and effect.
    </p>
    <Heading priority={2}>Changes</Heading>
    <p>
      Tieto reserves the right to amend these Terms and Conditions. You must review these Terms and
      Conditions from time to time for any amendments.
    </p>
  </section>
)
