import * as React from 'react'
import { Field } from 'react-final-form'
import { cn, SwitchField } from 'obap-core'
import { CookieItemPropsType } from './cookie-item.types'
import { styles } from './cookie-item.styles'

export const CookieItem: React.FC<CookieItemPropsType> = ({
  required,
  title,
  description,
  solutions,
  typeOfCookie,
}) => {
  return (
    <div className={cn(styles.cookieItem)}>
      {!required && (
        <div className={cn(styles.left)}>
          <div className={cn(styles.status)}>Off</div>
          <Field name={typeOfCookie as string} component={SwitchField} type="checkbox" />
          <div className={cn(styles.status)}>On</div>
        </div>
      )}
      <div className={cn(styles.right)}>
        <h3 className={cn(styles.title)}>{title}</h3>
        <p>{description}</p>
        <div className={cn(styles.solutions)}>
          <span className={cn(styles.listTitle)}>Affected Solutions:</span>
          <ul className={cn(styles.list)}>
            {solutions &&
              solutions.map((solution: string) => {
                return <li key={`${solution}Key`}>{solution}</li>
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}
