import { StyleSheet } from 'aphrodite/no-important'
import {
  createStyle,
  paddingVertical,
  XS_INDENT,
  StyleService,
  paddingHorizontal,
  MEDIA_MAX_CONTENT_WIDTH_M,
} from 'obap-core'
export const styles = StyleSheet.create({
  cookieSettingsFooter: createStyle({
    display: 'flex',
    backgroundColor: StyleService.colors.neutral.light,
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    ...paddingVertical(20),
    ...paddingHorizontal(20),
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      flexDirection: 'column',
    }),
  }),
  button: createStyle({
    fontFamily: StyleService.fontFamily,
    fontWeight: 500,
    lineHeight: '22px',
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      minWidth: 350,
      marginBottom: XS_INDENT,
      ':last-child': createStyle({
        marginBottom: 0,
      }),
    }),
  }),
})
