import { RouteComponentProps } from 'react-router-dom'

export enum SignUpPageSection {
  termsAndConditions = 'activateSignUpTermsAndConditions',
  dataPrivacy = 'activateSignUpDataPrivacy',
}
// eslint-disable-next-line
export interface SignUpPageOwnProps {}
export interface SignUpPageState {
  iframeHeight: number | undefined
  visibleSection: SignUpPageSection | null
  iframeUrl: string
}
// eslint-disable-next-line
export type SignUpPageProps = SignUpPageOwnProps & RouteComponentProps<{}>
