import * as React from 'react'

import toast from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import { queryStringToObject, AuthenticationProvider as CoreAuthProvider } from 'obap-core'

import { BASE_URL, REALM_ID, CLIENT_ID, MAIN_PAGE_PATH } from '@/utils/constants'
import { Notification401 } from '@/components/blocks/notifications'
//TODO: Add notifications

const getLoginError = (status: number) => {
  if (status === 401) {
    return <Notification401 />
  } else {
    return 'Unknown error'
  }
}
export const AuthenticationProvider: React.FC = ({ children }): JSX.Element => {
  const { push } = useHistory()
  const { pathname, search } = useLocation()

  const onLogin = React.useCallback(() => {
    if (search) {
      const { redirect } = queryStringToObject(search)
      redirect && push(redirect)
    } else {
      push(MAIN_PAGE_PATH)
    }
  }, [pathname, search, push])

  const onLoginError = React.useCallback(() => {
    toast.error(getLoginError(401))
  }, [])

  const onLogout = React.useCallback(() => push(MAIN_PAGE_PATH), [push])
  return (
    <CoreAuthProvider
      host={BASE_URL}
      realm={REALM_ID}
      client={CLIENT_ID}
      onLogin={onLogin}
      onLoginError={onLoginError}
      onLogout={onLogout}
    >
      {children}
    </CoreAuthProvider>
  )
}
