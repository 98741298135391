import { EMAIL_FIELD, PASSWORD_FIELD, TOTP_FIELD } from '@/utils/constants'

import { ValidatorHelper } from '@/utils/helpers/validator.helper'

import { SignInFormValues, FormValidatorType } from './sign-in-form.types'

export const formValidationConfig = (formStep: number): FormValidatorType => ({
  [EMAIL_FIELD]: {
    isRequired: true,
    comparator: [ValidatorHelper.usernameRegExp, ValidatorHelper.emailRegExp],
    typeErrorMessage: 'Please enter valid Email address',
  },
  [PASSWORD_FIELD]: {
    isRequired: true,
    comparator: ValidatorHelper.oldPasswordRegExp,
    typeErrorMessage: 'Please enter valid Password',
  },
  [TOTP_FIELD]: {
    comparator: values => {
      if (formStep && !/^\d{6}$/i.test(values.totp)) {
        return 'Please enter valid one-time password'
      }
      return
    },
  },
})

export const validate = (formStep: number) => {
  return (values: SignInFormValues) => {
    return ValidatorHelper.commonFormValidator<SignInFormValues, FormValidatorType>(
      values,
      formValidationConfig(formStep),
    )
  }
}
