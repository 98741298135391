export const getClearPathname = (pathname: string): string => pathname.replace(/\/\s*$/i, '')

export const composeApplicationRoutes = (routes: string[]): string => {
  const routesString = routes.join('|')
  return `(${routesString})`
}

export const queryObjectToString = (queryObject: any) => {
  if (queryObject && Object.keys(queryObject).length > 0) {
    const queryString = Object.keys(queryObject)
      .map((key: string) => {
        return `${key}=${queryObject[key]}`
      })
      .join('&')

    if (queryString) {
      return `?${queryString}`
    }
  }

  return ''
}
