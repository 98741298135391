import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { cn, Button } from 'obap-core'

import { CONSENT_COOKIE_NAME, COOKIE_SETTINGS_PATH } from '@/utils/constants'
import { initialCookieSettings } from '@/utils/configs/cookies-consent.config'

import { CookieBannerPropsType } from './cookie-banner.types'
import { styles } from './cookie-banner.styles'
import { saveCookies } from '@/utils/helpers'
import { LayoutContext } from '@/contexts'
//TODO: replace react-cookies with unified js cookie lib
const cookie = require('react-cookies') // eslint-disable-line

export const CookieBanner: React.FC<CookieBannerPropsType> = () => {
  const consentCookie = cookie.load(CONSENT_COOKIE_NAME)

  const [cCookie, setcCookie] = React.useState<boolean>(false)
  const [accepted, setAccepted] = React.useState<boolean>(false)
  const [showCookiebar, setShowCookiebar] = React.useState<boolean>(false)
  const { cookiesConsent } = React.useContext(LayoutContext)
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (consentCookie) {
      setcCookie(true)
    }
  }, [cCookie, accepted, cookiesConsent])

  React.useEffect(() => {
    const timeout: any = setTimeout(() => setShowCookiebar(true), 1500)
    return () => clearTimeout(timeout)
  }, [])

  const acceptCookies = () => {
    saveCookies(initialCookieSettings)
    setAccepted(true)
  }

  const checkHomePath = () => {
    if (pathname === '/') {
      return ''
    }
    return pathname
  }

  if (!cCookie) {
    return (
      <div className={cn(styles.cookieBar, [showCookiebar && styles.showCookieBar])}>
        <div className={cn(styles.cookieBarWrapper)}>
          <div className={cn(styles.cookieBarLeft)}>
            <div className={cn(styles.text)}>
              This website uses cookies to ensure you get the best experience on our website.
            </div>
          </div>
          <div className={cn(styles.cookieBarRight)}>
            <div className={cn(styles.cookieBarButtons)}>
              <Button
                rounded
                uppercased
                buttonType="default"
                styles={[styles.button]}
                component="link"
                to={`${checkHomePath()}${COOKIE_SETTINGS_PATH}`}
              >
                Cookie Settings
              </Button>
              <Button
                rounded
                uppercased
                buttonType="primary"
                styles={[styles.button]}
                onClick={acceptCookies}
              >
                Accept all cookies
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return null
}
