import * as React from 'react'
import { cn, Button } from 'obap-core'
import { CookieTab } from '@/components/blocks/cookies-consent'
import { cookieSettingsConfig } from '@/utils/configs/cookies-consent.config'
import { CookieSettingsFormProps, CookieItemsType } from './cookie-settings-form.types'
import { styles } from './cookie-settings-form.styles'

export const CookieSettingsForm: React.FC<CookieSettingsFormProps> = ({
  handleSubmit,
  handleAllCookies,
  values,
}): JSX.Element => {
  return (
    <form onSubmit={handleSubmit}>
      {cookieSettingsConfig.map(
        ({ title, typeOfCookie, required, description, solutions }: CookieItemsType) => {
          return (
            <CookieTab
              key={typeOfCookie}
              typeOfCookie={typeOfCookie}
              title={title}
              required={required}
              description={description}
              solutions={solutions}
              values={values}
            />
          )
        },
      )}
      <div className={cn(styles.cookieSettingsFooter)}>
        <Button rounded uppercased buttonType="primary" styles={[styles.button]} type="submit">
          Save current settings
        </Button>
        <Button
          rounded
          uppercased
          buttonType="primary"
          styles={[styles.button]}
          onClick={handleAllCookies}
        >
          Accept all cookies and close
        </Button>
      </div>
    </form>
  )
}
