import { BrowserRouter as Router } from 'react-router-dom'
// import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'obap-core'
import { DefaultLayout } from '@/components/layouts/default-layout'
import { ApplicationRouter } from '@/components/router/application-router'
import {
  QueryProvider,
  NotificationProvider,
  AuthenticationProvider,
  KeycloakNotificationsProvider,
} from '@/services/providers'
import { theme } from './services/theme'

// TODO: Add notifications
export const App = (): JSX.Element => {
  return (
    <Router>
      <QueryProvider>
        <ThemeProvider theme={theme}>
          <AuthenticationProvider>
            <NotificationProvider />
            <KeycloakNotificationsProvider />
            <div style={{ minHeight: '100%' }}>
              <DefaultLayout>
                <ApplicationRouter />
              </DefaultLayout>
            </div>
          </AuthenticationProvider>
        </ThemeProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryProvider>
    </Router>
  )
}
