import * as React from 'react'

import { cn } from 'obap-core'

import { NavigationBar } from '@/components/blocks/navigation-bar'
import { GeneralFooter } from '@/components/blocks/general-footer'
import { CookieBanner } from '@/components/blocks/cookies-consent'

import { DefaultLayoutComponentsProps as Props } from './default-layout.types'
import { styles } from './default-layout.styles'
//TODO: Check if GLobal Notification is needed
export const DefaultLayoutComponent: React.FC<Props> = (props): JSX.Element => {
  const { children, onNavigationBarExpanded } = props

  return (
    <div className={cn(styles.defaultLayout)}>
      <NavigationBar onExpanded={onNavigationBarExpanded} />
      <div className={cn(styles.mainSection)}>
        <section className={cn(styles.defaultLayoutContent)}>{children}</section>
      </div>
      <div className={cn(styles.notificationBar)}>
        <CookieBanner />
      </div>
      <GeneralFooter />
    </div>
  )
}
