import { StyleSheet } from 'aphrodite/no-important'
import {
  defaultTheme,
  createStyle,
  MEDIA_MAX_CONTENT_WIDTH_L,
  MEDIA_MAX_CONTENT_WIDTH_M,
  StyleService,
  DEFAULT_FONT_SIZE,
  paddingVertical,
  paddingHorizontal,
  XS_INDENT,
  S_INDENT,
} from 'obap-core'

import { theme } from '@/services/theme'

export const styles = StyleSheet.create({
  informationDropdown: createStyle({
    width: '80%',
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      width: '100%',
    }),
    [MEDIA_MAX_CONTENT_WIDTH_M]: createStyle({
      paddingBottom: XS_INDENT,
    }),
  }),
  userMenuElement: createStyle({
    position: 'relative',
  }),
  userDropdownContent: createStyle({
    right: 0,
    width: 200,
    left: 'auto',
    alignItems: 'flex-end',
    transform: 'translateY(100%)',
  }),
  userDropdownMenu: createStyle({
    width: '100%',
    backgroundColor: StyleService.colors.light,
    padding: 20,
    boxSizing: 'border-box',
  }),
  linksList: createStyle({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: defaultTheme.colors.light,
    boxSizing: 'border-box',
    ...paddingVertical(XS_INDENT),
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      ...paddingVertical(0),
      backgroundColor: 'transparent',
    }),
  }),
  link: createStyle({
    display: 'block',
    fontSize: DEFAULT_FONT_SIZE,
    textDecoration: 'none',
    fontFamily: defaultTheme.fonts.default.regular,
    width: '100%',
    color: defaultTheme.colors.linkColor,
    ...paddingHorizontal(S_INDENT),
    ...paddingVertical(XS_INDENT),
    ':hover': createStyle({
      backgroundColor: defaultTheme.colors.neutral.semilight,
    }),
    [MEDIA_MAX_CONTENT_WIDTH_L]: createStyle({
      color: defaultTheme.colors.defaultTextColor,
      fontFamily: theme.fonts.corporate.regular,
      ...paddingHorizontal(0),
      ':hover': createStyle({
        backgroundColor: 'transparent',
      }),
    }),
  }),
  navigationMenu: createStyle({
    ':nth-child(n) > ul > li > a': createStyle({
      fontFamily: theme.fonts.corporate.bold,
      fontWeight: 'bold',
      outline: 0,
    }),
  }),
  secondaryNavigationMenu: createStyle({
    ':nth-child(n) > ul > li > a': createStyle({
      fontFamily: theme.fonts.corporate.bold,
      textTransform: 'none',
      outline: 0,
    }),
  }),
})
