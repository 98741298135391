export const COOKIE_PREFIX = 'tob'
export const CONSENT_COOKIE_NAME = 'ccookie'
export const REALM_ID = 'credit'
export const CLIENT_ID = 'public_client'
export const AUTH_COOKIE_NAME = 'yzih1F5IVZvFRVWFjw'

export const SIMPLE_DATE_FORMAT = 'DD.MM.YYYY'

export const LOGOUT_CONTROL_ID = 'logout'

export const DATA_ROW_CONTENT_TYPE = 'DATA_ROW'
export const DATA_CARD_CONTENT_TYPE = 'DATA_CARD'
export const DATA_CARD_DEFAULT_CONTENT_TYPE = 'DATA_CARD_DEFAULT'
