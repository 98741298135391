import * as React from 'react'

import { LayoutContext } from '@/contexts'
import { PROFILE_PERSONAL_DATA_PATH } from '@/utils/constants'

export const WorkplaceSection: React.FunctionComponent = (props): JSX.Element => {
  const { children } = props

  const layoutContext = React.useContext(LayoutContext)
  React.useEffect(() => {
    layoutContext.setMenuItems([
      {
        index: 'profile',
        label: 'Profile',
        action: '',
        items: [
          {
            index: 'profile-settings',
            label: 'User Profile',
            action: PROFILE_PERSONAL_DATA_PATH,
          },
        ],
      },
    ])
  }, [])

  return <React.Fragment>{children}</React.Fragment>
}
