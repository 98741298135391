import * as React from 'react'

import { BreadcrumbsItem } from 'obap-core'

import { DefaultLayoutComponent } from './default-layout.component'

import { LayoutContext, LayoutContextValue } from '@/contexts'
import { menuItem } from '@/types/layout'
import { NavigationBarState } from './default-layout.types'

export const DefaultLayout: React.FC = ({ children }): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbsItem[]>([])
  const [menuItems, setMenuItems] = React.useState<menuItem[]>([])
  const [layoutOffset, setLayoutOffset] = React.useState(0)
  const [cookiesConsent, setCookiesConsent] = React.useState<boolean>()

  const onNavigationBarExpanded = React.useCallback((state: NavigationBarState) => {
    if (state.isExpaned && !state.isMobile) {
      setLayoutOffset(46)
    } else {
      setLayoutOffset(0)
    }
  }, [])

  const contextValue = React.useMemo<LayoutContextValue>(
    () => ({
      layoutOffset,
      breadcrumbs,
      menuItems,
      setBreadcrumbs,
      setMenuItems,
      headerHeight: layoutOffset,
      cookiesConsent,
      setCookiesConsent,
    }),
    [breadcrumbs, menuItems, layoutOffset, cookiesConsent],
  )

  return (
    <LayoutContext.Provider value={contextValue}>
      <DefaultLayoutComponent
        // notification={notification}
        onNavigationBarExpanded={onNavigationBarExpanded}
        // closeNotification={closeNotification}
      >
        {children}
      </DefaultLayoutComponent>
    </LayoutContext.Provider>
  )
}
