import * as React from 'react'
import { StyleService, cn } from 'obap-core'
import { ExpansionPanel } from '@/components/blocks/expansion-panel'
import { CookieItem } from '@/components/blocks/cookies-consent'
import Icon from '@mdi/react'
import { mdiCheck, mdiClose } from '@mdi/js'
import { CookieTabPropsType } from './cookie-tab.types'
import { styles } from './cookie-tab.styles'

export const CookieTab: React.FC<CookieTabPropsType> = ({
  title,
  required,
  description,
  solutions,
  typeOfCookie,
  values,
}) => {
  const checked = typeOfCookie && values[typeOfCookie]
  return (
    <React.Fragment>
      <ExpansionPanel
        key={`${title}Panel`}
        expandIconToRight
        title={title}
        statusIcon={
          <Icon
            path={checked ? mdiCheck : mdiClose}
            color={checked ? StyleService.colors.success.middle : StyleService.colors.danger.middle}
            size="32px"
            className={cn(styles.statusIcon)}
          />
        }
      >
        <CookieItem
          key={`${title}Tab`}
          required={required}
          title={title}
          description={description}
          solutions={solutions}
          values={values}
          typeOfCookie={typeOfCookie}
        />
      </ExpansionPanel>
    </React.Fragment>
  )
}
