import * as React from 'react'
import { BreadcrumbsItem } from 'obap-core'
import { menuItem } from '@/types/layout'

//TODO: CHECK IF GLOBAL TYPE IS WORKING CORRECTLY AND IS NOT CAUSING ERRORS
export interface LayoutContextValue {
  layoutOffset: number
  headerHeight?: number
  breadcrumbs?: BreadcrumbsItem[]
  menuItems?: menuItem[]
  cookiesConsent?: boolean
  setBreadcrumbs(breadcrumbs: BreadcrumbsItem[]): void
  setMenuItems(manuItems: menuItem[]): void
  setCookiesConsent(consent: boolean): void
}

export const LAYOUT_CONTEXT_VALUE = Object.freeze({
  layoutOffset: 0,
  headerHeight: 0,
  breadcrumbs: [],
  menuItems: [],
  setBreadcrumbs() {
    console.log()
  },
  setMenuItems() {
    console.log()
  },
  setCookiesConsent() {
    console.log()
  },
})

export const LayoutContext = React.createContext<LayoutContextValue>(LAYOUT_CONTEXT_VALUE)
