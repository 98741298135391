import { StyleSheet } from 'aphrodite/no-important'
import {
  M_INDENT,
  L_INDENT,
  createStyle,
  marginMixed,
  paddingMixed,
  StyleService,
  MEDIA_MAX_CONTENT_WIDTH_S,
} from 'obap-core'

export const styles = StyleSheet.create({
  cookieItem: createStyle({
    fontFamily: StyleService.fontFamily,
    display: 'flex',
    margin: 0,
    ...paddingMixed(25, 35),
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      flexDirection: 'column',
    }),
  }),
  left: createStyle({
    display: 'flex',
    marginRight: L_INDENT,
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      paddingBottom: M_INDENT,
    }),
  }),
  right: createStyle({
    display: 'block',
  }),
  title: createStyle({
    fontWeight: 600,
    fontSize: M_INDENT - 2,
    marginBottom: M_INDENT,
    lineHeight: `${M_INDENT + 2}px`,
  }),
  solutions: createStyle({
    marginTop: L_INDENT,
  }),
  listTitle: createStyle({
    color: StyleService.colors.neutral.dark,
    fontWeight: 500,
    fontSize: 17,
  }),
  list: createStyle({
    color: StyleService.colors.neutral.dark,
    ...marginMixed(15, 0),
    paddingLeft: M_INDENT,
    fontSize: 16,
    lineHeight: `${M_INDENT + 5}px`,
  }),
  status: createStyle({
    fontWeight: 600,
    ...marginMixed(0, 10),
    [MEDIA_MAX_CONTENT_WIDTH_S]: createStyle({
      ':first-of-type': createStyle({
        marginLeft: 0,
      }),
    }),
  }),
})
