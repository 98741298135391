import * as React from 'react'

import { cn } from 'obap-core'

import { WizardStepProps } from './types'
import { styles } from './wizard-step.styles'

export const WizardStep: React.FC<WizardStepProps> = ({ isActive, children }) => {
  const isWizardActive = Boolean(isActive)
  return <div className={cn(styles.hidden, [isWizardActive && styles.visible])}>{children}</div>
}
