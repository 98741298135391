import { defaultTheme, Theme } from 'obap-core'
const fontConfig = {
  light: {
    fontFamily: 'TietoevrySans1-Light',
    fontStyle: 'normal',
    fontWeight: 200,
    src: `url("/fonts/TietoevrySans1-Light.woff2") format("woff2"),
    url("/fonts/TietoevrySans1-Light.woff") format("woff")`,
  },
  regular: {
    fontFamily: 'TietoevrySans1-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url("/fonts/TietoevrySans1-Regular.woff2") format("woff2"),
    url("/fonts/TietoevrySans1-Regular.woff") format("woff")`,
  },
  bold: {
    fontFamily: 'TietoevrySans1-Bold',
    fontStyle: 'normal',
    fontWeight: 600,
    src: `url("/fonts/TietoevrySans1-Bold.woff2") format("woff2"),
    url("/fonts/TietoevrySans1-Bold.woff") format("woff")`,
  },
}

export const theme: Theme = {
  ...defaultTheme,
  fonts: {
    ...defaultTheme.fonts,
    corporate: {
      light: [fontConfig.light, 'sans-serif'],
      regular: [fontConfig.regular, 'sans-serif'],
      bold: [fontConfig.bold, 'sans-serif'],
    },
  },
}
