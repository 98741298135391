import * as H from 'history'

export interface OnlyPublicRouteProcessingOptions {
  endpoint: string
  location: H.Location
  history: H.History
}

export const changePageTitle = (title?: string): void => {
  if (title) {
    document.title = title
  }
}

export const onlyPublicRouteProcessing = (options: OnlyPublicRouteProcessingOptions): void => {
  const currentLocation = options.location.pathname
  const prevLocation = currentLocation.replace(options.endpoint, '')
  options.history.push(prevLocation)
}
