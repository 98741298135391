import * as React from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useKeycloak } from 'obap-core'

import { validate, SignInFormValues } from '@/components/forms/sign-in-form'
import { SignInPageComponent } from './sign-in-page.component'

import { SIGN_IN_PATH, SIGN_UP_PATH } from '@/utils/constants'

import { onlyPublicRouteProcessing } from '@/utils/helpers/common.helper'
import { SignInFormSpyData } from '@/components/forms/sign-in-form/sign-in-form.types'

const DEFAULT_FORM_DATA: SignInFormSpyData = {
  invalid: true,
  valid: false,
}

export const SignInPage: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()

  const { login, authentication } = useKeycloak()
  const [initialized, setInitialized] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [formData, setFormData] = React.useState<SignInFormSpyData>(DEFAULT_FORM_DATA)

  const onNextStep = React.useCallback(() => {
    setActiveStep(1)
  }, [])
  const onPrevStep = React.useCallback(() => {
    setActiveStep(0)
    setFormData({
      ...formData,
      values: {
        ...(formData.values || ({} as SignInFormValues)),
        totp: '',
      },
    })
  }, [formData])

  const onClose = React.useCallback(() => {
    onlyPublicRouteProcessing({
      location,
      history,
      endpoint: SIGN_IN_PATH,
    })
  }, [history, location])

  const onChange = React.useCallback(
    (spyData: SignInFormSpyData) => {
      if (initialized) {
        setFormData(spyData)
      }
    },
    [initialized],
  )

  const formValidation = React.useMemo(() => validate(activeStep), [activeStep])

  const signIn = React.useCallback(() => {
    login({
      username: formData?.values?.email || '',
      password: formData?.values?.password || '',
      totp: formData?.values?.totp || '',
    })
  }, [formData, login])

  const onSubmit = React.useCallback(() => {
    switch (activeStep) {
      case 0:
        onNextStep()
        break
      default:
        signIn()
    }
  }, [activeStep, signIn])

  const signUpPath = React.useMemo(
    () => location.pathname.replace(SIGN_IN_PATH, SIGN_UP_PATH),
    [location.pathname],
  )

  React.useEffect(() => setInitialized(true), [])

  return (
    <SignInPageComponent
      authentication={authentication}
      formStep={activeStep}
      formData={formData}
      signUpPath={signUpPath}
      onSubmit={onSubmit}
      formValidation={formValidation}
      onClose={onClose}
      onChange={onChange}
      onPrevStep={onPrevStep}
    />
  )
}
