import * as React from 'react'
import { Link } from 'react-router-dom'
import { Field, FormSpy } from 'react-final-form'
import { cn, TextInputField } from 'obap-core'

import { Wizard, WizardStep } from '@/components/blocks/wizard'

import { EMAIL_FIELD, PASSWORD_FIELD, TOTP_FIELD } from '@/utils/constants'

import { SignInFormComponentProps as Props } from './sign-in-form.types'

import { styles } from './sign-in-form.styles'

const FORM_SPY_CONFIG = {
  valid: true,
  invalid: true,
  values: true,
  touched: true,
}

export const SignInFormComponent: React.FunctionComponent<Props> = (props): JSX.Element => {
  const { activeStep, forgotPasswordPath, onChange, onFieldKeyDown } = props
  return (
    <React.Fragment>
      <FormSpy subscription={FORM_SPY_CONFIG} onChange={onChange} />
      <Wizard activeIndex={activeStep}>
        <WizardStep>
          <Field
            autoFocus
            name={EMAIL_FIELD}
            component={TextInputField}
            placeholder="Username or Email"
            blockStyle={styles.control}
            onKeyDown={onFieldKeyDown}
          />
          <Field
            name={PASSWORD_FIELD}
            component={TextInputField}
            type="password"
            placeholder="Password"
            blockStyle={styles.control}
            onKeyDown={onFieldKeyDown}
          />
          <p className={cn(styles.control, styles.rightSideText)}>
            <Link to={forgotPasswordPath} className={cn(styles.controlLink)}>
              Forgot password?
            </Link>
          </p>
        </WizardStep>
        <WizardStep>
          <Field
            autoFocus
            name={TOTP_FIELD}
            component={TextInputField}
            placeholder="One-time password"
            blockStyle={styles.control}
            onKeyDown={onFieldKeyDown}
          />
          <p className={cn(styles.control)}>
            If you have no access to one-time password
            <br />
            Please go to &nbsp;
            <Link to={forgotPasswordPath}>Forgot Password</Link>
          </p>
        </WizardStep>
      </Wizard>
    </React.Fragment>
  )
}
