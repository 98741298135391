import * as React from 'react'

import { bem } from '@/utils/helpers/bem-legacy.helper'

import { TitleProps } from './types'

import './styles.scss'

const cn = bem('title')

export class Title extends React.Component<TitleProps> {
  private highlightFirstWord = (): JSX.Element => {
    const { children } = this.props
    const string: string = (children || '') as string
    const firstSpaceIndex: number = string.indexOf(' ')

    let firstWord: string
    let defaultPart: string

    if (firstSpaceIndex < 0) {
      firstWord = string
      defaultPart = ''
    } else {
      firstWord = string.slice(0, firstSpaceIndex)
      defaultPart = string.slice(firstSpaceIndex)
    }

    return (
      <React.Fragment>
        <strong>{firstWord}</strong>
        {defaultPart}
      </React.Fragment>
    )
  }

  public render(): JSX.Element {
    const { children, size, light, bold, highlightFirstWord, className } = this.props

    const heading = size || 1

    return (
      <h3
        className={`${cn({
          [`heading-${heading}`]: true,
          light: Boolean(light),
          bold: Boolean(bold),
        })} ${className}`}
      >
        {highlightFirstWord ? this.highlightFirstWord() : children}
      </h3>
    )
  }
}
