import * as React from 'react'

import { cn } from 'obap-core'

import { ContentContainerProps as Props } from './content-container.types'
import { styles } from './content-container.styles'

export const ContentContainer: React.FC<Props> = props => {
  const { children, blockStyle } = props

  return <div className={cn(styles.container, blockStyle)}>{children}</div>
}
